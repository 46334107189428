import React from "react";
import {
  Box,
  Center,
  FlatList,
  HStack,
  Text,
  Pressable,
  ScrollView,
  Badge,
  Link,
} from "native-base";
import { useDispatch } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";
import moment from "moment";
import { FaGlobeAmericas } from "react-icons/fa";

// custom componenetnts
import UploadFiles from "../../../components/UploadFiles";

// firebase
import { db } from "../../../firebase/firebase-config";
import { collection, onSnapshot, query } from "@firebase/firestore";

// image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import generalImage from "../../../assets/generalAnnouncemnt.png";
import positionImg from "../../../assets/position.jpeg";
import preArrivalImg from "../../../assets/preArrival.png";

// lottie
import Lottie from "react-lottie";
import * as loadingLottie from "../../../assets/lottie/loading.json";
import * as empty from "../../../assets/lottie/empty-post.json";

export default function GeneralAnnouncements() {
  const dispatch = useDispatch();
  const [announcements, setAnnoucements] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useLayoutEffect(() => {
    dispatch(setCurrentStudentPage("Announcements"));
  });

  // listen to snapshot of announcements
  React.useLayoutEffect(() => {
    const q = query(collection(db, "announcements"));

    setLoading(true);
    const unsubScribe = onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        // push doc.data() to data array and add a seeMore state to it
        data.push({ ...doc.data(), seeMore: false });
      });

      setAnnoucements(
        data.sort(
          (a, b) =>
            new Date(b.dateEdited.seconds * 1000) -
            new Date(a.dateEdited.seconds * 1000)
        )
      );
      setFilteredAnnouncements(
        data.sort(
          (a, b) =>
            new Date(b.dateEdited.seconds * 1000) -
            new Date(a.dateEdited.seconds * 1000)
        )
      );
      setLoading(false);
    });

    return () => {
      unsubScribe();
    };
  }, []);

  const filters = ["All", "General", "Position", "Pre-Arrival Onboarding"];
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [filteredAnnouncements, setFilteredAnnouncements] =
    React.useState(announcements);

  // filter announcements
  const filterAnnouncements = (filter) => {
    if (filter === "All") {
      setCurrentIndex(0);
      setFilteredAnnouncements(announcements);
      return;
    } else if (filter === "General") {
      setCurrentIndex(1);
      setFilteredAnnouncements(
        announcements.filter((announcement) => announcement.type === "general")
      );
      return;
    } else if (filter === "Position") {
      setCurrentIndex(2);
      setFilteredAnnouncements(
        announcements.filter((announcement) => announcement.type === "position")
      );
      return;
    } else if (filter === "Pre-Arrival Onboarding") {
      setCurrentIndex(3);
      setFilteredAnnouncements(
        announcements.filter(
          (announcement) => announcement.type === "preArrivalOnboarding"
        )
      );
      return;
    }
  };

  const [numberOfLines, setNumberOfLines] = React.useState(4);

  console.log(filteredAnnouncements);

  return (
    <ScrollView>
      <Box alignItems={"center"} pt={10} w="100%">
        <Box w={["95%", "90%", "75%", "60%"]} rounded={"lg"} py={5} bg="white">
          <FlatList
            horizontal
            w="100%"
            data={filters}
            contentContainerStyle={{
              alignItems: "center",
              justifyContent: "center",
            }}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => (
              <Pressable
                mx={3}
                px={3}
                alignItems="center"
                justifyContent={"center"}
                py={1}
                bg={currentIndex === index ? "primary.500" : "gray.300"}
                onPress={() => {
                  setCurrentIndex(index);
                  filterAnnouncements(item);
                }}
                rounded="lg"
              >
                <Text color={currentIndex === index ? "white" : "gray.500"}>
                  {item}
                </Text>
              </Pressable>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        </Box>

        {loading ? (
          <Center mt={40} p={10} rounded="lg" bg="white">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingLottie,
              }}
              height={100}
              width={100}
            />
            <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
              loading
            </Text>
          </Center>
        ) : (
          <FlatList
            my={5}
            data={filteredAnnouncements}
            w={["95%", "90%", "75%", "60%"]}
            renderItem={({ item, index }) => {
              return (
                <Box key={index} rounded={"lg"} p={5} bg="white" mb={5}>
                  <HStack
                    alignItems={"flex-start"}
                    justifyContent="space-between"
                    space={4}
                    w="100%"
                    mb={3}
                  >
                    <Text fontSize={"xs"} mb={2}>
                      {/* days ago */}
                      {moment(item.dateEdited.seconds * 1000).fromNow()}
                      <FaGlobeAmericas
                        style={{
                          marginLeft: 2,
                          marginTop: "-1",
                          color: "darkgrey",
                        }}
                      />
                    </Text>
                    <Badge
                      rounded="lg"
                      colorScheme="secondary"
                      _text={{
                        fontSize: "xs",
                      }}
                    >
                      {item.type}
                    </Badge>
                  </HStack>
                  <Box rounded="md" overflow={"hidden"}>
                    <LazyLoadImage
                      src={
                        item.imageUrl
                          ? item.imageUrl
                          : item.type === "general"
                          ? generalImage
                          : item.type === "position"
                          ? positionImg
                          : preArrivalImg
                      }
                      placeholderSrc={
                        item.type === "general"
                          ? generalImage
                          : item.type === "position"
                          ? positionImg
                          : preArrivalImg
                      }
                      alt={item.title}
                      effect="blur"
                      style={{
                        aspectRatio: "16/5",
                        width: "100%",
                        objectFit: "cover",
                        backgroundColor: "rgba(10, 58, 90, .2)",
                      }}
                    />
                  </Box>
                  <Box mt={4} mb={2}>
                    <Text fontSize={"md"} fontWeight="600">
                      {item.title}
                    </Text>
                  </Box>

                  <Text
                    onPress={() => {
                      // set see more state to true for item
                      const newAnnouncements = [...filteredAnnouncements];
                      newAnnouncements[index].seeMore =
                        !newAnnouncements[index].seeMore;
                      setFilteredAnnouncements(newAnnouncements);
                    }}
                    // if see more is true, show full text else show truncated text
                    numberOfLines={item.seeMore ? null : 4}
                  >
                    {item.description}
                  </Text>

                  <Box mt={2}>
                    {item.hasDueDate && (
                      <Box>
                        <Text fontSize={"xs"} color="red.600">
                          Deadline:{" "}
                          {moment(item.dueDate.seconds * 1000).format(
                            "MMMM Do YYYY"
                          )}{" "}
                          at{" "}
                          {moment(item.dueDate.seconds * 1000).format("h:mm a")}
                        </Text>
                      </Box>
                    )}
                  </Box>
                  {item.link && (
                    <Pressable
                      alignItems="center"
                      justifyContent="center"
                      my={2}
                      bg="primary.100"
                      py={1}
                      px={3}
                      rounded="sm"
                      onPress={() => {
                        window.open(item.link, "_blank");
                      }}
                    >
                      <Text color="secondary.500">
                        {item.linkLabel ? item.linkLabel : item.link}
                      </Text>
                    </Pressable>
                  )}
                </Box>
              );
            }}
            keyExtractor={(item, index) => index.toString()}
            ListEmptyComponent={() => (
              <Center rounded="lg" overflow={"hidden"} bg="white">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: empty,
                  }}
                  height={400}
                  width={400}
                />
                <Text
                  mb={5}
                  mt={-10}
                  zIndex={20}
                  fontSize="lg"
                  textAlign={"center"}
                >
                  No Announcements were found
                </Text>
              </Center>
            )}
          />
        )}
      </Box>
    </ScrollView>
  );
}
