import React from "react";
import {
  Modal,
  Button,
  HStack,
  Avatar,
  Text,
  TextArea,
  Center,
  Box,
  Pressable,
  FormControl,
  Input,
} from "native-base";
import { BsFileImageFill } from "react-icons/bs";
import { FaRegFileVideo, FaExternalLinkSquareAlt } from "react-icons/fa";
import { VscFileSymlinkFile } from "react-icons/vsc";

export default function PostModal({
  value,
  onChangeText,
  imageUri,
  username,
  initials,
  renderImageInput,
  renderVideoInput,
  onPost,
  onClose,
  isOpen,
  onTriggerImageInput,
  onTriggerVideoInput,
  isPostDisabled,
  filename,
  loadingText,
  isLoading,
  onChangeLabel,
  onChangeLink,
  linkLabel,
  link,
  postImage,
  onChangePostImage,
}) {
  const [showImageInput, setShowImageInput] = React.useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Content>
        <Modal.CloseButton rounded={"full"} />
        <Modal.Header>Create Post</Modal.Header>
        <Modal.Body>
          <HStack my={2} alignItems="center" space={2}>
            <Avatar
              bg="primary.200"
              source={imageUri ? { uri: imageUri } : null}
            >
              {initials}
            </Avatar>
            <Text fontSize="lg" fontWeight={"500"}>
              {username}
            </Text>
          </HStack>
          <FormControl my={5}>
            <FormControl.Label
              _text={{
                fontSize: "md",
              }}
            >
              What do you have to share today?
            </FormControl.Label>

            <TextArea
              rounded="lg"
              size="lg"
              fontSize={["md", "lg"]}
              bg="primary.200"
              borderColor="primary.200"
              borderWidth={0}
              variant={"filled"}
              _focus={{
                bg: "primary.100",
              }}
              value={value}
              onChangeText={onChangeText}
            />
          </FormControl>
          <FormControl mb={5}>
            <FormControl.Label>Link</FormControl.Label>

            <Input
              rounded="lg"
              placeholder="eg https://www.thegenuineproject.org"
              placeholderTextColor={["secondary.500"]}
              bg="primary.200"
              fontSize={["md", "lg"]}
              borderColor="primary.200"
              borderWidth={0}
              variant={"filled"}
              _focus={{
                bg: "primary.100",
              }}
              value={link}
              onChangeText={onChangeLink}
            />
          </FormControl>
          <FormControl mb={5}>
            <FormControl.Label>Link Label</FormControl.Label>

            <Input
              rounded="lg"
              bg="primary.200"
              borderColor="primary.200"
              placeholder="eg. The Genuine Project"
              placeholderTextColor={["secondary.500"]}
              fontSize={["md", "lg"]}
              borderWidth={0}
              variant={"filled"}
              _focus={{
                bg: "primary.100",
              }}
              value={linkLabel}
              onChangeText={onChangeLabel}
            />
          </FormControl>
          {renderImageInput}
          {renderVideoInput}
          <Box bg="primary.200" px={5} py={3} rounded="lg">
            <HStack justifyContent={"space-between"} alignItems="center">
              <Text fontSize={["md", "lg"]}>
                {filename ? filename : "Add an image/video to your post"}
              </Text>
              <HStack space={5} alignItems="center">
                <Pressable
                  onPress={() => {
                    onTriggerImageInput();
                    setShowImageInput(false);
                  }}
                >
                  <BsFileImageFill color="rgba(10, 58, 90, 6)" size={30} />
                </Pressable>
                <Pressable
                  onPress={() => {
                    onTriggerVideoInput();
                    setShowImageInput(false);
                  }}
                >
                  <FaRegFileVideo color="rgba(10, 58, 90, 6)" size={30} />
                </Pressable>
                <Pressable
                  onPress={() => {
                    setShowImageInput(!showImageInput);
                  }}
                >
                  <FaExternalLinkSquareAlt
                    color="rgba(10, 58, 90, 6)"
                    size={30}
                  />
                </Pressable>
              </HStack>
            </HStack>
          </Box>

          {showImageInput && (
            <Box>
              <FormControl mt={5}>
                <FormControl.Label>Add A Video URL</FormControl.Label>

                <Input
                  rounded="lg"
                  bg="primary.200"
                  borderColor="primary.200"
                  placeholder="eg. youtube.com/somevideolink"
                  placeholderTextColor={["secondary.500"]}
                  fontSize={["md", "lg"]}
                  borderWidth={0}
                  variant={"filled"}
                  _focus={{
                    bg: "primary.100",
                  }}
                  value={postImage}
                  onChangeText={onChangePostImage}
                />
              </FormControl>
            </Box>
          )}
        </Modal.Body>

        <Box bg="gray.100" p={3} mb={5}>
          <Button
            variant={"solid"}
            rounded="lg"
            onPress={onPost}
            isDisabled={isPostDisabled}
            isLoading={isLoading}
            isLoadingText={loadingText}
          >
            POST
          </Button>
        </Box>
      </Modal.Content>
    </Modal>
  );
}
