import {
  goToAdmin,
  goToStudent,
  goToLoading,
  goToLogin,
} from "../slices/NavigationSlice";

import {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  logoutError,
  signupStart,
  signupSuccess,
  signupError,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordError,
  validateUserStart,
  validateUserSuccess,
  validateUserError,
} from "../slices/AuthenticationSlice";

//  firebase auth
import { auth, db } from "../../firebase/firebase-config";

// firebaes dependencies
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  setDoc,
  doc,
  updateDoc,
} from "@firebase/firestore";

import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendEmailVerification,
} from "@firebase/auth";

// app authentication middleware -> handles app entry point
export const appAuthenticationMiddleware = (isLoggedIn) => {
  return async (dispatch) => {
    dispatch(goToLoading());

    if (isLoggedIn === true) {
      const role = localStorage.getItem("role");
      // console.log('role', role)
      if (role !== null) {
        dispatch(routeToAppropraiteLayoutMiddleware(role));
        return;
      }
      return;
    }
    dispatch(goToLogin());
  };
};

// login middleware -> handles login
export const loginMiddleware = (email, password) => {
  return async (dispatch) => {
    dispatch(loginStart());

    try {
      await signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          getUser(email).then((user) => {
            if (user.role === "admin") {
              dispatch(loginSuccess(user));
              localStorage.setItem("role", user.role);
              localStorage.setItem("email", user.email);
              dispatch(routeToAppropraiteLayoutMiddleware(user.role));
            } else if (user.role === "student") {
              dispatch(
                validateUserAccount(
                  auth.currentUser.emailVerified,
                  auth.currentUser.uid
                )
              );
              dispatch(loginSuccess(user));
              localStorage.setItem("role", user.role);
              localStorage.setItem("email", user.email);
              dispatch(routeToAppropraiteLayoutMiddleware(user.role));
            } else {
              dispatch(loginError({ message: "Invalid User" }));
            }
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          if (errorCode === "auth/wrong-password") {
            dispatch(loginError({ message: "Wrong password." }));
          } else if (errorCode === "auth/user-not-found") {
            dispatch(loginError({ message: "User not found." }));
          } else {
            dispatch(
              loginError({
                message: errorMessage,
              })
            );
          }
        });
    } catch (error) {
      dispatch(loginError({ message: error.message }));
    }
  };
};

// get user -> handles getting of user in firebase
const getUser = (email) => {
  try {
    const q = query(collection(db, "app-users"), where("email", "==", email));
    const getQuerySnapshot = async () => {
      const querySnapshot = await getDocs(q);

      const users = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return users[0];
    };
    return getQuerySnapshot();
  } catch (error) {
    console.log(error.message);
  }
};

// routeToAppropraite Layout middleware
const routeToAppropraiteLayoutMiddleware = (role) => {
  return function (dispatch) {
    switch (role) {
      case "admin":
        dispatch(goToAdmin());
        break;
      case "student":
        dispatch(goToStudent());
        break;
      default:
        dispatch(goToLogin());
        break;
    }
  };
};

// validate user account -> handles validation of user account
export const validateUserAccount = (emailVerified, id) => {
  // update user account in firebase
  return async (dispatch) => {
    dispatch(validateUserStart());
    try {
      const appUser = doc(db, "app-users", id);
      await updateDoc(appUser, { emailVerified: emailVerified });
      dispatch(validateUserSuccess());
    } catch (error) {
      dispatch(validateUserError({ message: error.code }));
    }
  };
};

// logout middleware -> handles logout
export const logoutMiddleware = () => {
  return async (dispatch) => {
    dispatch(logoutStart());
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(logoutSuccess());
        localStorage.removeItem("role");
        localStorage.removeItem("email");
        dispatch(goToLogin());
      })
      .catch((error) => {
        // An error happene.
        dispatch(logoutError({ message: error.message }));
      });
  };
};

// signup middleware -> handles signup
export const signupMiddleware = (data) => async (dispatch) => {
  dispatch(signupStart());

  try {
    // create an account
    await createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (user) => {
        // create firebase user
        await setDoc(doc(db, "app-users", user.user.uid), {
          firstname: data.firstname,
          middlename: data.middlename,
          lastname: data.lastname,
          university: data.university,
          phone: data.phone,
          email: data.email,
          academicStanding: data.academicStanding,
          currentMajor: data.currentMajor,
          dateCreated: data.dateCreated,
          dateEdited: data.dateCreated,
          role: data.role,
          emailVerified: true,
          id: user.user.uid,
          isSeenByAdmin: false,
        }).then(async () => {
          await sendEmailVerification(user.user)
            .then(() => {
              dispatch(
                signupSuccess({
                  message:
                    "Account created successfully. Please check your email for verification.",
                })
              );
              dispatch(goToLogin());
            })
            .catch((error) => {
              dispatch(signupError({ message: error.message }));
              console.log(error);
            });
        });
      })
      .catch((error) => {
        dispatch(signupError({ message: error.message }));
      });
  } catch (error) {
    dispatch(signupError({ message: error.message }));
  }
};

// forgotpassword middleware
