import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",
  adminDisplay: "flex",
  studentDisplay: "flex",
  currentPage: "Dashboard",
  currentStudentPage: "General Announcements",
  newApplicantsCount: 0,
};

const NavigationSlice = createSlice({
  name: "NavigationSlice",
  initialState: INITIAL_STATE,
  reducers: {
    goToLoading: (state) => {
      state.ACTION_TYPE = goToLoading.toString();
    },

    goToLogin: (state) => {
      state.ACTION_TYPE = goToLogin.toString();
    },

    goToAdmin: (state) => {
      state.ACTION_TYPE = goToAdmin.toString();
    },
    goToStudent: (state) => {
      state.ACTION_TYPE = goToStudent.toString();
    },

    setAdminDisplay: (state, action) => {
      state.adminDisplay = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setCurrentStudentPage: (state, action) => {
      state.currentStudentPage = action.payload;
    },

    setStudentDisplay: (state, action) => {
      state.studentDisplay = action.payload;
    },

    setNewApplicantsCount: (state, action) => {
      state.newApplicantsCount = action.payload;
    },
  },
});

export const {
  goToLoading,
  goToLogin,
  goToAdmin,
  goToStudent,
  setAdminDisplay,
  setCurrentPage,
  setCurrentStudentPage,
  setStudentDisplay,
  setNewApplicantsCount,
} = NavigationSlice.actions;
export default NavigationSlice.reducer;
