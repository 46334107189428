/*
 * application page -> see all accepted applicants who have created their accounts successfully
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Center,
  ScrollView,
  Text,
  Heading,
  HStack,
  Button,
  Box,
  IconButton,
  Modal,
  useDisclose,
  Container,
  Image,
  AspectRatio,
  Link,
  Pressable,
  Badge,
  Avatar,
} from "native-base";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/slices/NavigationSlice";
import { getStudentsMiddleware } from "../../../redux/middlewares/AdminDashboardMiddleware";
import { getDocumentsMiddleware } from "../../../redux/middlewares/StudentMiddleware";
// icons
import { FiPlus, FiEdit, FiClose, FiX } from "react-icons/fi";
import { RiMoreLine } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";
// datatable
import DataTable from "react-data-table-component";
import { customStyles } from "../../../customStyles";
import { FilterComponent } from "../../../sharedComponents /FilterComponent";
import { studentsColumns } from "../../../utils/columns";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";

const fakeData = [
  {
    resume: "https://www.google.com/",
    passport: "https://www.google.com/",
    covid_test: "https://www.google.com/",
    flight_ticket: "https://www.google.com/",
  },
];

export default function ViewAllApplications() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const studentState = useSelector((state) => state.student);
  const { isOpen, onOpen, onClose } = useDisclose();

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Students"));
    setIsLoading(true);
    dispatch(getStudentsMiddleware());
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(dashboardState.students);

  const data = dashboardState.students.filter(
    (item) =>
      item.firstname.toLowerCase().includes(filterText.toLowerCase()) ||
      item.lastname.toLowerCase().includes(filterText.toLowerCase()) ||
      item.middlename.toLowerCase().includes(filterText.toLowerCase()) ||
      item.phone.toLowerCase().includes(filterText.toLowerCase()) ||
      item.email.toLowerCase().includes(filterText.toLowerCase()) ||
      item.university.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="flex-end" w="100%">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <ScrollView p={[5, 10]} bg="gray.200">
      <HStack alignItems="center" space={3}>
        <Heading fontSize={["xl", "2xl"]}>Study Abroad Students</Heading>
      </HStack>

      <Box mt={5} rounded="md" overflow={"hidden"} bg="white">
        <DataTable
          data={data}
          columns={[
            {
              name: "Actions",
              selector: (row) => row.title,
              sortable: true,
              cell: (row, index) => (
                <HStack alignItems="center" space={3}>
                  <IconButton
                    icon={<RiMoreLine size={20} />}
                    onPress={() => {
                      setSelectedData(data.find((item) => item.id === row.id));
                      dispatch(getDocumentsMiddleware(row.id));
                      onOpen();
                    }}
                  />
                  {!row.isSeenByAdmin && (
                    <Badge colorScheme="emerald">new</Badge>
                  )}
                </HStack>
              ),
            },
            ...studentsColumns,
          ]}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={isLoading}
          progressComponent={
            <Center mb={5}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loading,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={100}
                width={100}
              />
              <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
                Loading...
              </Text>
            </Center>
          }
          responsive
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
        />
      </Box>

      <Modal
        size="full"
        alignItems={"center"}
        justifyContent="center"
        isOpen={isOpen}
        bg={"rgba(0,0,0,0.5)"}
      >
        <Box
          bg="white"
          w={["90%", "80%", "70%", "50%"]}
          p={5}
          rounded="md"
          h="70vh"
        >
          <Center>
            <Avatar size="2xl" source={{ uri: selectedData.profilePicture }}>
              {selectedData.firstname && selectedData?.firstname?.charAt(0)}
              {selectedData.middlename && selectedData?.middlename?.charAt(0)}
              {selectedData.lastname && selectedData?.lastname?.charAt(0)}
            </Avatar>

            <IconButton
              zIndex={100}
              icon={<FiX />}
              colorScheme="danger"
              onPress={() => {
                onClose();
              }}
              position="absolute"
              right={3}
              top={0}
            />
          </Center>
          <Center>
            <Text fontSize={"xl"} fontWeight="bold">
              {selectedData?.firstname} {selectedData?.middlename}{" "}
              {selectedData?.lastname}
            </Text>
          </Center>

          <HStack mt={5} space={2} flexWrap="wrap">
            {
              //  array.map function for document object in saved data
              Object.keys(studentState.documents ?? {}).map((key) => (
                <Box key={key} my={0.5}>
                  <Button
                    onPress={() =>
                      window.open(studentState.documents[key], "_blank")
                    }
                    px={3}
                    py={1}
                    leftIcon={
                      <BiLinkExternal
                        color="white"
                        style={{
                          marginBottom: "-1px",
                        }}
                      />
                    }
                  >
                    <Text color="white"> {key}</Text>
                  </Button>
                </Box>
              ))
            }
          </HStack>

          <ScrollView>
            <Box px={10}>
              <Container my={4}>
                <Text fontWeight={"bold"}>Account Verified</Text>
                {selectedData.emailVerified ? (
                  <Badge colorScheme="green">verified</Badge>
                ) : (
                  <Badge colorScheme="red">not verified</Badge>
                )}
              </Container>

              <Container my={4}>
                <Text fontWeight={"bold"}>Phone Number</Text>
                <Text>{selectedData.phone}</Text>
              </Container>

              <Container my={4}>
                <Text fontWeight={"bold"}>Email</Text>
                <Text>{selectedData?.email}</Text>
              </Container>

              <Container my={4}>
                <Text fontWeight={"bold"}>Academic Standing</Text>
                <Text>{selectedData?.academicStanding}</Text>
              </Container>

              <Container my={4}>
                <Text fontWeight={"bold"}>Current Major</Text>
                <Text>{selectedData?.currentMajor}</Text>
              </Container>

              <Container my={4}>
                <Text fontWeight={"bold"}>University</Text>
                <Text>{selectedData?.university}</Text>
              </Container>
            </Box>
          </ScrollView>
        </Box>
      </Modal>
    </ScrollView>
  );
}
