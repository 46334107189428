/*
 *  Admin sidebar
 */

// dependencies
import React from "react";
import {
  Stack,
  Center,
  Text,
  Pressable,
  useTheme,
  ScrollView,
  Image,
  IconButton,
  HStack,
  Tooltip,
  Box,
  Badge,
} from "native-base";
import { NavLink } from "react-router-dom";
import { FiChevronLeft, FiChevronRight, FiLogOut } from "react-icons/fi";

// logo
import logo from "../assets/logo-sm.png";

import { useSelector, useDispatch } from "react-redux";
import { logoutMiddleware } from "../redux/middlewares/AuthenticationMiddleware";

import { setAdminDisplay } from "../redux/slices/NavigationSlice";

export default function AdminSidebar(props) {
  const { menuData = [] } = props;
  const { colors, fontSizes } = useTheme();
  const dispatch = useDispatch();
  const { adminDisplay } = useSelector((state) => state.navigation);
  const { newApplicantsCount } = useSelector((state) => state.navigation);

  return (
    <Stack bg="primary.500" h="100%">
      <Stack>
        <HStack px={5} py={4} alignItems="center" justifyContent={"flex-start"}>
          <Image
            source={logo}
            alt="logo"
            resizeMode="contain"
            h={7}
            w={7}
            tintColor={"white"}
          />

          <Text
            color="white"
            ml={3}
            fontSize="lg"
            display={[adminDisplay ?? "flex", adminDisplay ?? "flex"]}
          >
            Genuine
          </Text>
        </HStack>
        <HStack
          px={4}
          mb={2}
          alignItems="center"
          justifyContent={"flex-start"}
          bg="secondary.50"
          py={5}
        >
          <Text color="white" ml={2} fontSize="sm">
            {adminDisplay === "none" ? "AD" : "ADMIN DASHBOARD"}
          </Text>
        </HStack>
      </Stack>
      <ScrollView>
        {menuData.map((item, index) => (
          <Tooltip
            placement="right"
            offset={100}
            left={5}
            label={item.name}
            bg="primary.500"
            display={[
              adminDisplay === "none" ? "flex" : "none",
              adminDisplay === "none" ? "flex" : "none",
            ]}
          >
            <NavLink
              key={index}
              to={item.link}
              style={({ isActive }) => {
                return {
                  display: "block",
                  color: isActive ? colors["primary"][500] : "white",
                  fontFamily: "Montserrat Alternates",
                  fontSize: fontSizes["sm"],
                  textDecoration: "none",
                  backgroundColor: isActive ? colors["blue"][300] : "",
                  margin: ".5rem 1rem",
                  borderRadius: "5px",
                };
              }}
            >
              <Pressable
                rounded={"md"}
                px={".5rem"}
                py={".5rem"}
                w="100%"
                _hover={{
                  backgroundColor: "blue.300",
                }}
                flexDir="row"
                alignItems={"center"}
              >
                {item.icon}
                <Stack
                  display={[adminDisplay ?? "flex", adminDisplay ?? "flex"]}
                  ml={5}
                >
                  {item.name}
                </Stack>
                <Box position="absolute" right={-10} top={-6}>
                  {item.name === "Applications" && newApplicantsCount > 0 && (
                    <Badge colorScheme="green" rounded="lg">
                      <Text fontSize="xs">{newApplicantsCount}</Text>
                    </Badge>
                  )}
                </Box>
              </Pressable>
            </NavLink>
          </Tooltip>
        ))}
      </ScrollView>

      {/* logout */}
      <Box px={5} mb={5}>
        <Tooltip
          placement="right"
          offset={100}
          left={5}
          label={"logout"}
          bg="red.500"
          display={[
            adminDisplay === "none" ? "flex" : "none",
            adminDisplay === "none" ? "flex" : "none",
          ]}
        >
          <Pressable
            rounded={"md"}
            px={".5rem"}
            py={".5rem"}
            w="100%"
            bg="red.500"
            flexDir="row"
            alignItems={"center"}
            justifyContent="center"
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => dispatch(logoutMiddleware())}
          >
            <FiLogOut color="white" size={20} />
            <Stack>
              <Text
                color="white"
                ml={2}
                display={[adminDisplay ?? "flex", adminDisplay ?? "flex"]}
              >
                Logout
              </Text>
            </Stack>
          </Pressable>
        </Tooltip>
      </Box>

      <Center>
        <IconButton
          icon={
            adminDisplay === "none" ? (
              <FiChevronRight size={30} color="white" />
            ) : (
              <FiChevronLeft size={30} color="white" />
            )
          }
          onPress={() =>
            dispatch(
              setAdminDisplay(adminDisplay === "none" ? "block" : "none")
            )
          }
        />
      </Center>
    </Stack>
  );
}
