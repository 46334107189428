import React from "react";
import { HStack, Box, Hidden, ScrollView  } from "native-base";

import { Routes, Route, Navigate } from "react-router-dom";

// pages
import CreateAnnouncement from "../pages/admin/announcements/CreateAnnouncement";
import EditAnnouncement from "../pages/admin/announcements/EditAnnouncement";
import ViewAnnouncements from "../pages/admin/announcements/ViewAnnouncements";
import ViewAllApplications from "../pages/admin/applications/ViewAllApplications";
import AdminDashBoard from "../pages/admin/dashboard/AdminDashboard";
import ViewAllStudents from "../pages/admin/students/ViewAllStudents";
import CommunityPosts from "../pages/student/communityBoard/CommunityPosts";

// sidebar
import Sidebar from "../components/AdminSidebar";
// navbar
import AdminNavbar from "../components/AdminNavbar";

// Sidebar icons
import { MdSpaceDashboard } from "react-icons/md";
import { AiFillAlert } from "react-icons/ai";
import { HiDocument } from "react-icons/hi";
import { FaUserGraduate } from "react-icons/fa";
import FullHeight from "react-full-height";
import { BsFillSignpostSplitFill } from "react-icons/bs";

// menu data
const menu = [
  {
    name: "Dashboard",
    link: "/admin/dashboard",
    icon: <MdSpaceDashboard size={20} />,
  },
  {
    name: "Announcements",
    link: "/admin/announcements",
    icon: <AiFillAlert size={20} />,
  },
  {
    name: "Applications",
    link: "/admin/applications",
    icon: <HiDocument size={20} />,
  },
  {
    name: "Students",
    link: "/admin/students",
    icon: <FaUserGraduate size={20} />,
  },
  {
    name: "Community Board",
    link: "/admin/community-posts",
    icon: <BsFillSignpostSplitFill size={20} />,
  },
];

export default function AdminLayout() {
  return (
    <FullHeight canExceed>
      {/* shows for large windows  */}
      <Hidden till="md">
        <HStack h="100vh" w="100%">
          <Sidebar menuData={menu} />
          <Box flex={1} bg="gray.200">
            <Routes>
              <Route path="/admin/dashboard" element={<AdminDashBoard />} />
              <Route
                path="/admin/announcements/create"
                element={<CreateAnnouncement />}
              />
              <Route
                path="/admin/announcements/edit/:id"
                element={<EditAnnouncement />}
              />
              <Route
                path="/admin/announcements"
                element={<ViewAnnouncements />}
              />
              <Route
                path="/admin/applications"
                element={<ViewAllApplications />}
              />
              <Route
                path="/admin/community-posts"
                element={<CommunityPosts />}
              />
              <Route path="/admin/students" element={<ViewAllStudents />} />
              {/* default redirect */}
              <Route path="*" element={<Navigate to="/admin/dashboard" />} />
            </Routes>
          </Box>
        </HStack>
      </Hidden>
      {/* shows for small windows  */}
      <Hidden from="md">
        <ScrollView
          h="100vh"
          stickyHeaderHiddenOnScroll={true}
          stickyHeaderIndices={[0]}
          bg="gray.200"
        >
          <AdminNavbar menuData={menu} />
          <Box>
            <Routes>
              <Route path="/admin/dashboard" element={<AdminDashBoard />} />
              <Route
                path="/admin/announcements/create"
                element={<CreateAnnouncement />}
              />
              <Route
                path="/admin/announcements/edit/:id"
                element={<EditAnnouncement />}
              />
              <Route
                path="/admin/announcements"
                element={<ViewAnnouncements />}
              />
              <Route
                path="/admin/applications"
                element={<ViewAllApplications />}
              />
              <Route
                path="/admin/community-posts"
                element={<CommunityPosts />}
              />
              <Route path="/admin/students" element={<ViewAllStudents />} />

              {/* default redirect */}
              <Route path="*" element={<Navigate to="/admin/dashboard" />} />
            </Routes>
          </Box>
        </ScrollView>
      </Hidden>
    </FullHeight>
  );
}
