/*
 *   Root layout page -> main application entry point for routing
 */

// dependencies
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { appAuthenticationMiddleware } from "../redux/middlewares/AuthenticationMiddleware";
import {
  goToLoading,
  goToAdmin,
  goToStudent,
} from "../redux/slices/NavigationSlice";

// Layouts
import AuthLayout from "./AuthLayout";
import StudentLayout from "./StudentLayout";
import AdminLayout from "./AdminLayout";
import LoadingPage from "../pages/misc/LoadingPage";

export default function RootLayout() {
  const nav = useSelector((state) => state.navigation);
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(appAuthenticationMiddleware(auth.isLoggedIn));
  }, [auth.isLoggedIn, dispatch]);

  if (nav.ACTION_TYPE === goToLoading.toString()) {
    return <LoadingPage />;
  } else if (nav.ACTION_TYPE === goToAdmin.toString()) {
    return <AdminLayout />;
  } else if (nav.ACTION_TYPE === goToStudent.toString()) {
    return <StudentLayout />;
  }

  return <AuthLayout />;
}
