import React from "react";
import { Modal, Box, Button } from "native-base";

export default function PickImageModal({
  isOpen,
  onClose,
  message,
  onPick,
  onCancel,
  onConfirm,
  isLoading,
  isLoadingText,
  title
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {message}
          <Box my={5}>
            <input
              type="file"
              onChange={onPick}
              style={{
                fontFamily: "Montserrat Alternates",
              }}
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group variant="ghost" space="2">
            <Button onPress={onCancel}>Cancel</Button>
            <Button
              isLoading={isLoading}
              isLoadingText={isLoadingText}
              onPress={onConfirm}
            >
              Upload
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
