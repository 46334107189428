import React from "react";
import {
  Box,
  Image,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Pressable,
  useToast,
  IconButton,
  ScrollView,
  Select,
  CheckIcon,
} from "native-base";
import logo from "../../assets/logo.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  resetActionType,
  signupError,
  signupSuccess,
} from "../../redux/slices/AuthenticationSlice";
import { signupMiddleware } from "../../redux/middlewares/AuthenticationMiddleware";

export default function Signup() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authentication);
  const toast = useToast();
  const navigate = useNavigate();

  const [firstname, setFirstname] = React.useState("");
  const [middlename, setMiddlename] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [university, setUniversity] = React.useState("");
  const [currentMajor, setCurrentMajor] = React.useState("");
  const [academicStanding, setAcademicStanding] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const onSignup = () => {
    if (
      !firstname ||
      !lastname ||
      !university ||
      !phone ||
      !email ||
      !password ||
      !confirmPassword
    ) {
      toast.show({
        title: "Fields cannot be empty",
        description: "Please fill all the fields",
        placement: "top",
        status: "error",
      });
      return;
    } else if (password !== confirmPassword) {
      toast.show({
        title: "Passwords do not match",
        description: "Please confirm your password",
        placement: "top",
        status: "error",
      });
      return;
    }

    const data = {
      firstname,
      middlename,
      lastname,
      university,
      currentMajor,
      academicStanding,
      phone,
      password,
      email,
      dateCreated: new Date(),
      role: "student",
    };

    dispatch(signupMiddleware(data));
  };

  // side effects of create account
  React.useEffect(() => {
    if (authState.ACTION_TYPE === signupSuccess.toString()) {
      toast.show({
        title: "Account created successfully",
        description: "You can now log into your account",
        placement: "top",
        status: "success",
      });
      dispatch(resetActionType());
      navigate("/login", {
        state: {
          email,
        },
      });
    } else if (authState.ACTION_TYPE === signupError.toString()) {
      toast.show({
        title: "Account creation failed",
        description: authState.signupMessage,
        placement: "top",
        status: "error",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.ACTION_TYPE]);

  return (
    <ScrollView
      h="100vh"
      bg="primary.500"
      py={10}
      contentContainerStyle={{ alignItems: "center" }}
    >
      <Box
        bg="secondary.50"
        shadow="4"
        w={["90%", "80%", "60%", "50%"]}
        p={[10]}
        rounded="md"
        alignItems={"center"}
      >
        <Image
          source={logo}
          h={[90, 100]}
          w={[150, 200]}
          resizeMode="contain"
        />

        <Stack space={8} w={["100%", "80%"]}>
          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              First Name
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="First Name"
              value={firstname}
              onChangeText={(text) => setFirstname(text)}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Middle Name
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="Middle Name"
              value={middlename}
              onChangeText={(text) => setMiddlename(text)}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Last Name
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="Last Name"
              value={lastname}
              onChangeText={(text) => setLastname(text)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Phone Number
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="+0 000 000 0000"
              value={phone}
              onChangeText={(text) => setPhone(text)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              University
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="University"
              value={university}
              onChangeText={(text) => setUniversity(text)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Current Major
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="University"
              value={currentMajor}
              onChangeText={(text) => setCurrentMajor(text)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Academic Standing in Fall 2022
            </FormControl.Label>
            <Select
              selectedValue={academicStanding}
              minWidth="200"
              fontSize="md"
              bg="white"
              accessibilityLabel="Choose your academic standing"
              placeholder="Choose your academic standing"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={setAcademicStanding}
            >
              <Select.Item label="Freshman" value="Freshman" />
              <Select.Item label="Sophomore" value="Sophomore" />
              <Select.Item label="Junior" value="Junior" />
              <Select.Item label="Senior" value="Senior" />
            </Select>
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Email
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="type student or admin to login"
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Password
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="password"
              secureTextEntry={!showPassword}
              value={password}
              onChangeText={(text) => setPassword(text)}
              InputRightElement={
                <IconButton
                  icon={showPassword ? <FiEye /> : <FiEyeOff />}
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Confirm Password
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="password"
              secureTextEntry={!showConfirmPassword}
              value={confirmPassword}
              onChangeText={(text) => setConfirmPassword(text)}
              InputRightElement={
                <IconButton
                  icon={showConfirmPassword ? <FiEye /> : <FiEyeOff />}
                  onPress={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              }
            />
          </FormControl>
          <Button
            size="lg"
            colorScheme="emerald"
            mb={5}
            isLoading={authState.isSignupLoading}
            onPress={() => onSignup()}
          >
            SIGN UP
          </Button>
          <Pressable
            alignItems={"center"}
            mt={-5}
            mb={5}
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => navigate("/login")}
          >
            <Text color="white">Already have an account? Log in.</Text>
          </Pressable>
        </Stack>
      </Box>
    </ScrollView>
  );
}
