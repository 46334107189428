import { combineReducers } from "redux";
import NavigationSlice from "./NavigationSlice";
import AuthenticationSlice from "./AuthenticationSlice";
import AdminDashboardSlice from "./AdminDashboardSlice";
import StudentSlice from "./StudentSlice";


export default combineReducers({
  navigation: NavigationSlice,
  authentication: AuthenticationSlice,
  adminDashboard: AdminDashboardSlice,
  student: StudentSlice,
});
