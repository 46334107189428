import React from "react";
import { Box, HStack, Hidden, ScrollView } from "native-base";

import { Routes, Route, Navigate } from "react-router-dom";

// pages
import GeneralAnnouncements from "../pages/student/announcements/GeneralAnnouncements";
import RequireSubmissions from "../pages/student/announcements/RequireSubmissions";
import PreArrivalOnboarding from "../pages/student/announcements/PreArrivalOnboarding";
import Calendar from "../pages/student/announcements/Calendar";
import CommunityPosts from "../pages/student/communityBoard/CommunityPosts";
import EditDocument from "../pages/student/documents/EditDocument";
import SubmitDocument from "../pages/student/documents/SubmitDocument";
import ViewDocuments from "../pages/student/documents/ViewDocuments";
import AccountDetails from "../pages/student/profile/AccountDetials";
import EditAccount from "../pages/student/profile/EditAccount";
import ChangePassword from "../pages/student/profile/ChangePassword";
import Documents from "../pages/student/profile/Documents";

// sidebar
import StudentSidebar from "../components/StudentSidebar";
//  navbar
import StudentNavbar from "../components/StudentNavbar";

// Sidebar icons
import { AiFillAlert } from "react-icons/ai";
import { FaUserGraduate } from "react-icons/fa";
import { ImUpload } from "react-icons/im";
import { BsCalendarRangeFill } from "react-icons/bs";
import { BsFillSignpostSplitFill } from "react-icons/bs";

// menu
const menu = [
  {
    name: "Account Details",
    link: "/student/profile/edit",
    icon: <FaUserGraduate size={20} />,
  },
  {
    name: "Announcements",
    link: "/student/announcements/general",
    icon: <AiFillAlert size={20} />,
  },
  {
    name: "Required Submissions",
    link: "/student/announcements/require-submissions",
    icon: <ImUpload size={20} />,
  },

  {
    name: "Community Board",
    link: "/student/community-board",
    icon: <BsFillSignpostSplitFill size={20} />,
  },
];

export default function StudentLayout() {
  return (
    <>
      {/* show on large windows  */}
      <Hidden till="md">
        <HStack h="100vh">
          <StudentSidebar menuData={menu} />
          <Box flex={8} bg="gray.100">
            <Routes>
              <Route
                path="/student/announcements/general"
                element={<GeneralAnnouncements />}
              />
              <Route
                path="/student/announcements/require-submissions"
                element={<RequireSubmissions />}
              />
              <Route
                path="/student/announcements/pre-arrival-onboarding"
                element={<PreArrivalOnboarding />}
              />
              <Route
                path="/student/announcements/calendar"
                element={<Calendar />}
              />
              <Route
                path="/student/community-board"
                element={<CommunityPosts />}
              />
              <Route
                path="/student/documents/edit/:id"
                element={<EditDocument />}
              />
              <Route
                path="/student/documents/submit"
                element={<SubmitDocument />}
              />
              <Route path="/student/documents" element={<ViewDocuments />} />
              <Route path="/student/profile" element={<AccountDetails />}>
                <Route path="edit" element={<EditAccount />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="documents" element={<Documents />} />
              </Route>
              <Route path="/student/profile/edit" element={<EditAccount />} />
              {/* default redirect */}
              <Route
                path="*"
                element={<Navigate to="/student/announcements/general" />}
              />
            </Routes>
          </Box>
        </HStack>
      </Hidden>
      {/* show on small windows */}
      <Hidden from="md">
        <ScrollView
          h="100vh"
          stickyHeaderHiddenOnScroll={true}
          stickyHeaderIndices={[0]}
          bg="gray.100"
        >
          <StudentNavbar menuData={menu} />
          <Box flex={8}>
            <Routes>
              <Route
                path="/student/announcements/general"
                element={<GeneralAnnouncements />}
              />
              <Route
                path="/student/announcements/require-submissions"
                element={<RequireSubmissions />}
              />
              <Route
                path="/student/announcements/pre-arrival-onboarding"
                element={<PreArrivalOnboarding />}
              />
              <Route
                path="/student/announcements/calendar"
                element={<Calendar />}
              />
              <Route
                path="/student/community-board"
                element={<CommunityPosts />}
              />
              <Route
                path="/student/documents/edit/:id"
                element={<EditDocument />}
              />
              <Route
                path="/student/documents/submit"
                element={<SubmitDocument />}
              />
              <Route path="/student/documents" element={<ViewDocuments />} />
              <Route path="/student/profile" element={<AccountDetails />}>
                <Route path="edit" element={<EditAccount />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="documents" element={<Documents />} />
              </Route>
              {/* default redirect */}
              <Route
                path="*"
                element={<Navigate to="/student/announcements/general" />}
              />
            </Routes>
          </Box>
        </ScrollView>
      </Hidden>
    </>
  );
}
