import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // action type
  ACTION_TYPE: "",
  // loading states
  isLoginLoading: false,
  isLogoutLoading: false,
  isSignupLoading: false,
  isForgotPasswordLoading: false,
  isValidateUserLoading: false,
  // messages
  loginMessage: "",
  signupMessage: "",
  forgotPasswordMessage: "",
  validateUserMessage: "",

  // login state
  isLoggedIn: false,

  // user data
  user: {},
};

export const AuthenticationSlice = createSlice({
  name: "AuthenticationSlice",
  initialState,
  reducers: {
    // login actions
    loginStart: (state) => {
      state.ACTION_TYPE = loginStart.toString();
      state.isLoginLoading = true;
      state.loginMessage = "";
    },

    loginSuccess: (state, action) => {
      state.ACTION_TYPE = loginSuccess.toString();
      state.isLoginLoading = false;
      state.isLoggedIn = true;
      state.user = action.payload;
    },

    updateUser: (state, action) => {
      state.ACTION_TYPE = updateUser.toString();
      state.user = action.payload;
    },

    loginError: (state, action) => {
      state.ACTION_TYPE = loginError.toString();
      state.isLoginLoading = false;
      state.loginMessage = action.payload.message;
    },

    // logout actions
    logoutStart: (state) => {
      state.ACTION_TYPE = logoutStart.toString();
      state.isLogoutLoading = true;
    },

    logoutSuccess: (state, action) => {
      state.ACTION_TYPE = logoutSuccess.toString();
      state.isLogoutLoading = false;
      state.isLoggedIn = false;
    },

    logoutError: (state, action) => {
      state.ACTION_TYPE = logoutError.toString();
      state.isLogoutLoading = false;
    },

    // signup actions
    signupStart: (state) => {
      state.ACTION_TYPE = signupStart.toString();
      state.isSignupLoading = true;
      state.signupMessage = "";
    },

    signupSuccess: (state, action) => {
      state.ACTION_TYPE = signupSuccess.toString();
      state.isSignupLoading = false;
      state.signupMessage = action.payload.message;
    },

    signupError: (state, action) => {
      state.ACTION_TYPE = signupError.toString();
      state.isSignupLoading = false;
      state.signupMessage = action.payload.message;
    },

    // forgot password actions
    forgotPasswordStart: (state) => {
      state.ACTION_TYPE = forgotPasswordStart.toString();
      state.isForgotPasswordLoading = true;
      state.forgotPasswordMessage = "";
    },

    forgotPasswordSuccess: (state, action) => {
      state.ACTION_TYPE = forgotPasswordSuccess.toString();
      state.isForgotPasswordLoading = false;
      state.forgotPasswordMessage = action.payload.message;
    },

    forgotPasswordError: (state, action) => {
      state.ACTION_TYPE = forgotPasswordError.toString();
      state.isForgotPasswordLoading = false;
      state.forgotPasswordMessage = action.payload.message;
    },

    // validate user actions
    validateUserStart: (state) => {
      state.ACTION_TYPE = validateUserStart.toString();
      state.isValidateUserLoading = true;
      state.validateUserMessage = "";
    },

    validateUserSuccess: (state, action) => {
      state.ACTION_TYPE = validateUserSuccess.toString();
      state.isValidateUserLoading = false;
      state.validateUserMessage = action.payload.message;
    },

    validateUserError: (state, action) => {
      state.ACTION_TYPE = validateUserError.toString();
      state.isValidateUserLoading = false;
      state.validateUserMessage = action.payload.message;
    },

    // reset action type
    resetActionType: (state) => {
      state.ACTION_TYPE = "";
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  logoutError,
  signupStart,
  signupSuccess,
  signupError,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordError,
  validateUserStart,
  validateUserSuccess,
  validateUserError,
  updateUser,
  resetActionType,
} = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
