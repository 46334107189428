import React from "react";
import {
  Center,
  Box,
  Image,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Pressable,
  useToast,
  IconButton,
} from "native-base";
import logo from "../../assets/logo.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { loginMiddleware } from "../../redux/middlewares/AuthenticationMiddleware";
import {
  loginSuccess,
  loginError,
  resetActionType,
} from "../../redux/slices/AuthenticationSlice";

export default function Login() {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.authentication);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  // onLogin -> handles login
  const onLogin = () => {
    if (!email || !password) {
      toast.show({
        title: "Error",
        description: "Please fill in all fields",
        placement: "top",
        duration: 5000,
        status: "danger",
      });
      return;
    }

    dispatch(loginMiddleware(email, password));
  };

  // side effects of login
  React.useEffect(() => {
    if (authState.ACTION_TYPE === loginSuccess.toString()) {
      toast.show({
        title: "Login Success",
        placement: "top",
        status: "success",
      });
      dispatch(resetActionType());
    } else if (authState.ACTION_TYPE === loginError.toString()) {
      toast.show({
        title: "Login Error",
        description: authState.loginMessage,
        placement: "top",
        status: "danger",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.ACTION_TYPE]);

  return (
    <Center h="100vh" bg="primary.500">
      <Box
        bg="secondary.50"
        shadow="4"
        w={["90%", "80%", "60%", "50%"]}
        p={[10]}
        rounded="md"
        alignItems={"center"}
      >
        <Image
          source={logo}
          h={[90, 100]}
          w={[150, 200]}
          resizeMode="contain"
        />

        <Stack space={8} w={["100%", "80%"]}>
          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Email
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="user@domain.com"
              value={email}
              autoCapitalize="none"
              onChangeText={(text) => setEmail(text)}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  onLogin();
                }
              }}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Password
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="password"
              secureTextEntry={!showPassword}
              value={password}
              onChangeText={(text) => setPassword(text)}
              InputRightElement={
                <IconButton
                  icon={showPassword ? <FiEye /> : <FiEyeOff />}
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
              onKeyPress={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  onLogin();
                }
              }}
            />
          </FormControl>
          <Pressable
            alignItems={"flex-end"}
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => navigate("/forgot-password")}
          >
            <Text color="white">Forgot Password?</Text>
          </Pressable>
          <Button
            size="lg"
            colorScheme="emerald"
            mb={5}
            onPress={() => onLogin()}
            isLoading={authState.isLoginLoading}
            _loading={{
              bg: "emerald.200",
            }}
          >
            LOGIN
          </Button>
          <Pressable
            alignItems={"center"}
            mt={-5}
            mb={5}
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => navigate("/signup")}
          >
            <Text color="white">Don't have an account? Sign up.</Text>
          </Pressable>
        </Stack>
      </Box>
    </Center>
  );
}
