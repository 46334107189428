import React from "react";
import {
  Text,
  ScrollView,
  Stack,
  Box,
  Avatar,
  HStack,
  Pressable,
  useDisclose,
  Button,
  Center,
} from "native-base";
import moment from "moment";
import { FaGlobeAmericas } from "react-icons/fa";
import { HiSaveAs } from "react-icons/hi";
import { saveAs } from "file-saver";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";
import {
  createPostMiddleware,
  getPostsMiddleware,
  editPostMiddleware,
  deletePostMiddleware,
  getUser,
} from "../../../redux/middlewares/StudentMiddleware";

// custom component
import PostModal from "../../../components/modals/PostModal";
import EditPostModal from "../../../components/modals/EditPostModal";
import Loading from "../../../sharedComponents /Loading";
import Alert from "../../../components/Alert";

// firebase
import { storage } from "../../../firebase/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import Lottie from "react-lottie";
import * as empty from "../../../assets/lottie/empty-post.json";

import placeholder from "../../../assets/placeholder.png";

import { FcLink } from "react-icons/fc";

import ReactPlayer from "react-player";

export default function CommunityPosts() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const posts = useSelector((state) => state.student.posts);
  const isGetPostsLoading = useSelector(
    (state) => state.student.isGetPostsLoading
  );

  const { isOpen, onOpen, onClose } = useDisclose();
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [post, setPost] = React.useState({});
  const [file, setFile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [editModal, setEditModal] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [type, setType] = React.useState("");

  const fileInput = React.useRef();
  const videoInput = React.useRef();

  const createPost = async (file) => {
    if (!file) {
      setLoading(true);
      const obj = {
        postBody: post.body,
        postLink: post?.link ?? "",
        postLinkLabel: post?.linkLabel ?? "",
        userId: user.id,
        postedBy: `${user.firstname} ${user.middlename} ${user.lastname}`,
        userProfilePicture: user.profilePicture,
        createdAt: new Date(),
        type: type,
        postImage: post?.postImage ?? "",
      };
      dispatch(createPostMiddleware(obj));
      setLoading(false);
      onClose();
      setPost({})
      return;
    }

    const storageRef = ref(
      storage,
      `/posts/${user?.firstname}-${user?.middlename}-${user?.lastname}/${file.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          const obj = {
            postBody: post.body,
            postLink: post?.link ?? "",
            postLinkLabel: post?.linkLabel ?? "",
            postImage: url,
            userId: user.id,
            postedBy: `${user.firstname} ${user.middlename} ${user.lastname}`,
            userProfilePicture: user.profilePicture,
            createdAt: new Date(),
            type: type,
          };
          dispatch(createPostMiddleware(obj));
          setLoading(false);
          onClose();
          setPost({});
        });
      }
    );
  };

  const updatePost = async (file) => {
    if (!file) {
      setLoading(true);
      const obj = {
        postBody: post.body,
        postLink: post?.link ?? "",
        postLinkLabel: post?.linkLabel ?? "",
        userId: user.id,
        postedBy: `${user.firstname} ${user.middlename} ${user.lastname}`,
        userProfilePicture: user.profilePicture,
        createdAt: new Date(),
        type: type,
        postImage: post?.postImage ?? "",
      };
      dispatch(editPostMiddleware(selected.id, obj));
      setLoading(false);
      setEditModal(false);
      setPost({});
      return;
    }

    const storageRef = ref(
      storage,
      `/posts/${user?.firstname}-${user?.middlename}-${user?.lastname}/${file.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          const obj = {
            postBody: post.body,
            postLink: post?.link ?? "",
            postLinkLabel: post?.linkLabel ?? "",
            postImage: url,
            userId: user.id,
            postedBy: `${user.firstname} ${user.middlename} ${user.lastname}`,
            userProfilePicture: user.profilePicture,
            createdAt: new Date(),
            type: type,
          };
          dispatch(editPostMiddleware(selected.id, obj));
          setLoading(false);
          setEditModal(false);
          setPost({});
        });
      }
    );
  };

  React.useLayoutEffect(() => {
    dispatch(setCurrentStudentPage("Community Board"));
    dispatch(getPostsMiddleware());
    dispatch(getUser(user.id));
  }, []);

  const deletePost = async () => {
    dispatch(deletePostMiddleware(selected.id));
    setDeleteAlert(false);
  };

  console.log(post);

  return (
    <ScrollView h="100vh">
      <Center my={[5, 10]}>
        <Stack w={["95%", "80%", "75%", "60%"]} space={5}>
          {/* post header */}
          <Box rounded={"lg"} px={10} py={5} bg="white">
            <HStack space={5} alignItems="center" justifyContent={"center"}>
              <Avatar
                size="md"
                bg="primary.200"
                source={{ uri: user.profilePicture }}
                m={0}
                p={0}
              >
                A
              </Avatar>

              <Button
                rounded="full"
                flex={1}
                pl={5}
                variant="subtle"
                justifyContent="flex-start"
                onPress={onOpen}
              >
                Share your experience?
              </Button>
            </HStack>
          </Box>

          {/* posts */}
          {posts?.length > 0 ? (
            posts?.map((post, index) => (
              <Box
                rounded={"lg"}
                pt={5}
                bg="white"
                key={post.id}
                px={0}
                overflow="hidden"
              >
                <HStack
                  px={[2, 10]}
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <HStack alignItems={"center"} space={3}>
                    <Avatar
                      size="md"
                      bg="primary.200"
                      source={{ uri: post.userProfilePicture }}
                      m={0}
                      p={0}
                    />
                    <Box>
                      <Text>{post.postedBy}</Text>
                      {/* posted minutes ago */}
                      <Text fontSize="xs">
                        {moment(
                          new Date(post.createdAt.seconds * 1000)
                        ).fromNow()}{" "}
                        <FaGlobeAmericas />
                      </Text>
                    </Box>
                  </HStack>

                  {post.userId === user.id && (
                    <HStack alignItems={"center"} space={1}>
                      <Pressable
                        p={2}
                        _hover={{
                          bg: "primary.200",
                          borderRadius: "full",
                        }}
                        onPress={() => {
                          setSelected(post);
                          setEditModal(true);
                          setPost({
                            body: post.postBody,
                            link: post.postLink,
                            linkLabel: post.postLinkLabel,
                            postImage: post.postImage,
                          });
                        }}
                      >
                        <FiEdit2 />
                      </Pressable>

                      <Pressable
                        p={2}
                        _hover={{
                          bg: "primary.200",
                          borderRadius: "full",
                        }}
                        onPress={() => {
                          setSelected(post);
                          setDeleteAlert(true);
                        }}
                      >
                        <FiTrash2 />
                      </Pressable>
                    </HStack>
                  )}
                </HStack>

                <Box my={4} px={[2, 10]}>
                  <Text>{post.postBody}</Text>
                </Box>

                {post.postLink && (
                  <Box mb={4} px={[2, 10]}>
                    <Pressable
                      width="fit-content"
                      onPress={() => {
                        window.open(post.postLink, "_blank");
                      }}
                    >
                      <Text color="primary.400" fontSize="xs">
                        <FcLink />{" "}
                        {post.postLinkLabel
                          ? post.postLinkLabel
                          : post.postLink}{" "}
                        <FcLink />
                      </Text>
                    </Pressable>
                  </Box>
                )}

                {post.type === "image" ? (
                  <LazyLoadImage
                    effect="blur"
                    src={post.postImage}
                    placeholderSrc={placeholder}
                    style={{
                      aspectRatio: "4/3",
                      width: "100%",
                      objectFit: "cover",
                      backgroundColor: "rgba(10, 58, 90, .2)",
                    }}
                    alt="post"
                    // visibleByDefault={true}
                  />
                ) : post.type === "video" ? (
                  <ReactPlayer
                    url={post.postImage}
                    controls={true}
                    width="100%"
                    volume={0}
                    loop={true}
                    muted={true}
                    style={{
                      backgroundColor: "rgba(10, 58, 90, .2)",
                      objectFit: "cover",
                    }}
                  />
                ) : null}

                {post.type === "image" && (
                  <Pressable
                    p={3}
                    rounded="full"
                    bg="rgba(184, 193, 199, 0.2)"
                    _pressed={{
                      bg: "rgba(184, 193, 199, 0.4)",
                    }}
                    position={"absolute"}
                    right={5}
                    bottom={5}
                    onPress={() => {
                      saveAs(post.postImage, "post.png");
                    }}
                  >
                    <HiSaveAs color="white" size={20} />
                  </Pressable>
                )}
              </Box>
            ))
          ) : (
            <Center rounded="lg" overflow={"hidden"} bg="white">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: empty,
                }}
                height={400}
                width={400}
              />
              <Text
                mb={5}
                mt={-10}
                zIndex={20}
                fontSize="lg"
                textAlign={"center"}
              >
                No posts yet. Be the first to share your experience.
              </Text>
            </Center>
          )}
        </Stack>
      </Center>

      {/* loading */}
      <Loading isLoading={isGetPostsLoading} loadingText="Loading posts..." />

      {/* post modal */}
      <PostModal
        isOpen={isOpen}
        onClose={onClose}
        imageUri={user.profilePicture}
        username={`${user?.firstname ?? "Admin"} ${user?.middlename ?? ""} ${
          user?.lastname ?? ""
        }`}
        initials={`${user?.firstname?.charAt(0).toUpperCase() ?? "A"}${
          user?.middlename?.charAt(0).toUpperCase() ?? ""
        }${user?.lastname?.charAt(0).toUpperCase() ?? ""}`}
        value={post?.body}
        linkLabel={post?.linkLabel}
        link={post?.link}
        postImage={post?.postImage}
        isPostDisabled={isDisabled}
        filename={file?.name}
        isLoading={loading}
        loadingText={`${progress}% Posting...`}
        onChangeText={(text) => {
          setPost({ ...post, body: text });
          setIsDisabled(false);
        }}
        onChangeLabel={(text) => {
          setPost({ ...post, linkLabel: text });
        }}
        onChangeLink={(text) => {
          setPost({ ...post, link: text });
        }}
        onChangePostImage={(file) => {
          setPost({ ...post, postImage: file });
          setType("video");
        }}
        renderImageInput={
          <input
            type="file"
            id="file"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        }
        renderVideoInput={
          <input
            type="file"
            id="file"
            ref={videoInput}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        }
        onTriggerImageInput={() => {
          fileInput.current.click();
          setType("image");
        }}
        onTriggerVideoInput={() => {
          videoInput.current.click();
          setType("video");
        }}
        onPost={() => {
          createPost(file);
        }}
      />

      {/* Edit Post Modal */}
      <EditPostModal
        isOpen={editModal}
        onClose={() => {
          setSelected(null);
          setEditModal(false);
        }}
        imageUri={selected?.userProfilePicture}
        username={selected?.postedBy}
        initials={`${selected?.postedBy?.charAt(0).toUpperCase() ?? "A"}`}
        value={post?.body}
        linkLabel={post?.linkLabel}
        link={post?.link}
        isPostDisabled={isDisabled}
        filename={file?.name}
        isLoading={loading}
        postImage={post?.postImage}
        loadingText={`${progress}% Posting...`}
        onChangeText={(text) => {
          setPost({ ...post, body: text });
        }}
        onChangeLabel={(text) => {
          setPost({ ...post, linkLabel: text });
        }}
        onChangeLink={(text) => {
          setPost({ ...post, link: text });
        }}
        onChangePostImage={(file) => {
          setPost({ ...post, postImage: file });
          setType("video");
        }}
        renderInput={
          <input
            type="file"
            id="file"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        }
        renderVideoInput={
          <input
            type="file"
            id="file"
            ref={videoInput}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        }
        onTriggerImageInput={() => {
          fileInput.current.click();
          setType("image");
        }}
        onTriggerVideoInput={() => {
          videoInput.current.click();
          setType("video");
        }}
        onPost={() => {
          updatePost(file);
        }}
      />

      {/* delete alert */}
      <Alert
        isOpen={deleteAlert}
        title="Delete Post"
        message="Are you sure you want to delete this post?"
        cancelText={"cancel"}
        confirmText={"delete"}
        onCancel={() => {
          setDeleteAlert(false);
        }}
        onConfirm={() => {
          deletePost();
        }}
      />
    </ScrollView>
  );
}
