import React from "react";
import { Center, Stack, Text, Container, useToast } from "native-base";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";
import {
  resetActionType,
  uploadDocumentError,
  uploadDocumentSuccess,
} from "../../../redux/slices/StudentSlice";
import {
  uploadDocumentMiddleware,
  getDocumentsMiddleware,
} from "../../../redux/middlewares/StudentMiddleware";

// images
import passport from "../../../assets/passport.jpeg";
import visa from "../../../assets/visa.jpeg";
import yellowfever from "../../../assets/yellowfever.jpeg";
import covid from "../../../assets/covid.jpeg";
import flight from "../../../assets/flight.jpeg";

// custom components
import DocumentCard from "../../../components/cards/DocumentCard";
import PickImageModal from "../../../components/modals/PickImageModal";
import { DOC_TYPE } from "../../../config/constants";
import { Toast } from "bootstrap";
import Loading from "../../../sharedComponents /Loading";

// firebase
import { storage } from "../../../firebase/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";

export default function Document() {
  const dispatch = useDispatch();
  const studentState = useSelector((state) => state.student);
  const user = useSelector((state) => state.authentication.user);
  const toast = useToast();

  // React.useLayoutEffect(() => {
  //   dispatch(setCurrentStudentPage("Edit Account"));
  // });

  const [docType, setDocType] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [file, setFile] = React.useState(null);

  const uploadFile = async (file) => {
    if (!file) return;

    const storageRef = ref(
      storage,
      `/documents/${user?.firstname}-${user?.middlename}-${user?.lastname}/${docType}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          const obj = {};
          obj[docType] = url;

          dispatch(uploadDocumentMiddleware(user.id, obj));
          setLoading(false);
          setShowModal(false);
        });
      }
    );
  };

  // side effects of document upload
  React.useEffect(() => {
    if (studentState.ACTION_TYPE === uploadDocumentSuccess.toString()) {
      toast.show({
        title: "Success",
        description: studentState.uploadDocumentMessage,
        status: "success",
        placement: "top",
      });
       dispatch(getDocumentsMiddleware(user.id));
      dispatch(resetActionType());
    } else if (studentState.ACTION_TYPE === uploadDocumentError.toString()) {
      toast.show({
        title: "Error",
        description: studentState.uploadDocumentMessage,
        status: "danger",
        placement: "top",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentState.ACTION_TYPE]);

  // getDocuments
  React.useEffect(() => {
    dispatch(getDocumentsMiddleware(user.id));
  }, []);

  console.log(studentState.documents, "documents");

  console.log(file)

  return (
    <Stack alignItems={"center"} space="5" mb={10}>
      <DocumentCard
        source={passport}
        submited={studentState.documents?.passport ? true : false}
        title="Passport"
        onView={() => {
          window.open(studentState.documents?.passport, "_blank");
        }}
        onSubmit={() => {
          setDocType(DOC_TYPE.PASSPORT);
          setTitle("Upload Passport");
          setShowModal(true);
        }}
        renderDescription={
          <Container>
            <Text color="red.500">*Required</Text>
            <Text>
              All U.S. interns must have a valid passport which must be valid at
              least six months beyond the intern abroad program's end date.
            </Text>
            <Text my={2}>
              Estimated cost: <Text color="green.600">$165.00 USD</Text>
            </Text>
            <Text>5-10 week processing time</Text>
          </Container>
        }
      />
      <DocumentCard
        source={visa}
        submited={studentState.documents?.visa ? true : false}
        title="Visa"
        onView={() => {
          window.open(studentState.documents?.visa, "_blank");
        }}
        onSubmit={() => {
          setDocType(DOC_TYPE.VISA);
          setTitle("Upload Visa");
          setShowModal(true);
        }}
        renderDescription={
          <Container>
            <Text color="red.500">*Required</Text>
            <Text>
              All U.S. interns are required to apply and obtain a J-1 Visa to
              legally participate in the internship program.
            </Text>
            <Text my={2}>
              Estimated cost: <Text color="green.600">$165.00</Text> (per
              person)
            </Text>
            <Text>
              Prior to completion of the J-1 Visa, interns will need to complete
              an online DS-2019 application.
            </Text>
            <Text my={2}>5-10 week processing time</Text>
          </Container>
        }
      />
      <DocumentCard
        source={yellowfever}
        submited={studentState.documents?.yellowfever ? true : false}
        title="Yellow Fever Vaccine"
        onView={() => {
          window.open(studentState.documents?.yellowfever, "_blank");
        }}
        onSubmit={() => {
          setDocType(DOC_TYPE.YELLOW_FEVER);
          setTitle("Upload Yellow Fever Vaccine");
          setShowModal(true);
        }}
        renderDescription={
          <Container>
            <Text color="red.500">*Required</Text>
            <Text>
              Yellow fever shot must be given at least 10 days prior to entering
              Ghana.
            </Text>
            <Text my={2}>
              Vaccine cost can range from{" "}
              <Text color="green.600">$150.00-$350.00 USD</Text>
            </Text>
            <Text>
              A general physician doesn’t carry the yellow fever shot — interns
              will have to visit a specialty travel vaccine clinic within their
              geographic locations to have the shot administered.
            </Text>
          </Container>
        }
      />
      <DocumentCard
        source={covid}
        submited={studentState.documents?.covid ? true : false}
        title="Covid Vaccine"
        onView={() => {
          window.open(studentState.documents?.covid, "_blank");
        }}
        onSubmit={() => {
          setDocType(DOC_TYPE.COVID);
          setTitle("Upload Covid Vaccine");
          setShowModal(true);
        }}
        renderDescription={
          <Container>
            <Text color="red.500">*Required</Text>
            <Text>
              A travel Covid-19 Test must be taken 2 days prior to boarding
              their flight to Ghana.
            </Text>
          </Container>
        }
      />
      <DocumentCard
        source={flight}
        submited={studentState.documents?.flightDetails ? true : false}
        title="Flight Details"
        onView={() => {
          window.open(studentState.documents?.flightDetails, "_blank");
        }}
        onSubmit={() => {
          setDocType(DOC_TYPE.FLIGHT);
          setTitle("Upload Flight Details");
          setShowModal(true);
        }}
        renderDescription={
          <Container>
            <Text color="red.500">*Required</Text>
            <Text>
              We will provide airport pick-up and drop-off services for your
              flight. Knowing your flight details will help us to ensure that
              you are arriving and departing from the correct airport.
            </Text>
          </Container>
        }
      />

      {/* pick and upload document */}
      <PickImageModal
        title={title}
        isOpen={showModal}
        visible={showModal}
        message="Please select a file to upload"
        isLoading={loading}
        isLoadingText={` uploading ${progress}%`}
        onCancel={() => {
          setShowModal(false);
        }}
        onConfirm={() => {
          if (!file) {
            toast.show({
              title: "Error",
              description: "Please select a file to upload",
              status: "error",
              placement: "top",
            });
          }
          uploadFile(file);
        }}
        onClose={() => {
          setShowModal(false);
        }}
        onPick={(e) => {
          setFile(e.target.files[0]);
        }}
      />

      {/* loading  */}
      <Loading
        isLoading={studentState.isUploadDocumentLoading}
        loadingText=" updating student records"
      />
    </Stack>
  );
}
