/*
 *  general loading page
 */

// loading asset
import loading from "../../assets/icon-9.1s-195px.svg";

import { Center, Image } from "native-base";

import React from "react";

export default function LoadingPage() {
  return (
    <Center h="100vh">
      <Image source={loading} size="md" alt="loading" rounded="full" />
    </Center>
  );
}
