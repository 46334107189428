import React from "react";
import {
  HStack,
  Menu,
  Pressable,
  Text,
  Image,
  Avatar,
  Stack,
} from "native-base";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiLogOut } from "react-icons/fi";

// logo
import logo from "../assets/logo-sm.png";

import { useSelector, useDispatch } from "react-redux";

import { logoutMiddleware } from "../redux/middlewares/AuthenticationMiddleware";

import { BsCalendarRangeFill } from "react-icons/bs";

export default function StudentNavbar(props) {
  const { menuData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const { currentStudentPage } = useSelector((state) => state.navigation);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [avatarHovered, setAvatarHovered] = React.useState(false);
  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      p={5}
      bg="primary.500"
      zIndex={100}
    >
      <HStack space={5} alignItems="center">
        {/* menu */}
        <Menu
          px={5}
          offset={23}
          right={10}
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <FiMenu size={25} color="white" />
              </Pressable>
            );
          }}
        >
          {menuData.map((item, index) => (
            <Menu.Item
              px={"1rem"}
              py={".5rem"}
              rounded="md"
              my={1}
              ml={1}
              flexDir="row"
              bg={index === currentIndex ? "primary.500" : "transparent"}
              alignItems={"center"}
              key={index}
              _hover={{
                backgroundColor:
                  index === currentIndex ? "primary.500" : "gray.200",
              }}
              onPress={() => {
                navigate(item.link);
                setCurrentIndex(index);
              }}
            >
              <Text color={index === currentIndex ? "white" : "primary.500"}>
                {item.icon}
              </Text>
              <Text
                color={index === currentIndex ? "white" : "primary.500"}
                ml={3}
                mb={1}
                fontWeight="medium"
              >
                {item.name}
              </Text>
            </Menu.Item>
          ))}
          {/* calendar */}
          <Menu.Item
            px={"1rem"}
            py={".5rem"}
            rounded="md"
            my={1}
            ml={1}
            flexDir="row"
            alignItems={"center"}
            _hover={{
              backgroundColor: "gray.200",
            }}
            onPress={() => {
              window.open(
                "https://calendar.google.com/calendar/u/1?cid=Y183OTRtZmpsNWthYWF0Z200MWduZW8wMjltb0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t"
              );
            }}
          >
            <Text color={"primary.500"}>
              <BsCalendarRangeFill size={20} />
            </Text>
            <Text color={"primary.500"} ml={3} mb={-1} fontWeight="medium">
              Canlendar
            </Text>
          </Menu.Item>
          {/* logout */}
          <Pressable
            rounded={"md"}
            py={".5rem"}
            m={2}
            bg="red.500"
            flexDir="row"
            alignItems={"center"}
            justifyContent="center"
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => dispatch(logoutMiddleware())}
          >
            <FiLogOut color="white" size={20} />
            <Stack>
              <Text color="white" ml={2}>
                Logout
              </Text>
            </Stack>
          </Pressable>
        </Menu>

        <Text color="white" fontSize="lg" width={"15ch"} isTruncated>
          {currentStudentPage}
        </Text>
      </HStack>

      <HStack space={5} alignItems="center">
        <Pressable
          onMouseEnter={() => {
            setAvatarHovered(true);
          }}
          onMouseLeave={() => {
            setAvatarHovered(false);
          }}
          onPress={() => {
            navigate("/student/profile/edit");
          }}
        >
          <Avatar
            size="sm"
            _text={{ color: "white" }}
            source={{
              uri: user.profilePicture ? user.profilePicture : "",
            }}
          >
            {avatarHovered ? <FiEdit color="white" size={15} /> : "FAT"}
          </Avatar>
        </Pressable>

        <Image
          source={logo}
          alt="logo"
          resizeMode="contain"
          h={7}
          w={7}
          tintColor={"white"}
        />
      </HStack>
    </HStack>
  );
}
