import { AlertDialog, Button } from "native-base";
import React from "react";

export default function Alert({
  isOpen,
  onCancel,
  onConfirm,
  title,
  message,
  confirmText,
  cancelText,
}) {
  return (
    <AlertDialog isOpen={isOpen} motionPreset="fade">
      <AlertDialog.Content>
        <AlertDialog.Header fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialog.Header>
        <AlertDialog.Body>{message}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button
            variant={"ghost"}
            onPress={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button
            variant={"ghost"}
            ml="3"
            onPress={() => {
              onConfirm();
            }}
          >
            {confirmText}
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
