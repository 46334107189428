import React from "react";
import {
  Box,
  Hidden,
  Stack,
  Text,
  useTheme,
  HStack,
  ScrollView,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import {getUser} from '../../../redux/middlewares/StudentMiddleware'
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { FiEdit, FiLock, FiFile } from "react-icons/fi";

export default function AccountDetails() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);


  React.useLayoutEffect(() => {
    dispatch(setCurrentStudentPage("Account"));
    dispatch(getUser(user.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { colors, fontSizes } = useTheme();

  return (
    <ScrollView stickyHeaderHiddenOnScroll={true} stickyHeaderIndices={[0]}>
      <Stack space={5} py={6} px={5} shadow={1} bg="white">
        <Text fontSize="xl" fontWeight={"semibold"} textAlign="center" mb={3}>
          Account Details
        </Text>
        {/* link to outlet */}
        <HStack justifyContent={"center"} space={10}>
          <NavLink
            to="/student/profile/edit"
            style={({ isActive }) => {
              return {
                display: "block",
                color: isActive ? colors["primary"][500] : "gray",
                fontFamily: "Montserrat Alternates",
                fontSize: fontSizes["sm"],
                textDecoration: "none",
                borderBottom: isActive ? "solid" : "",
                paddingBottom: 5,
              };
            }}
          >
            Edit Account
          </NavLink>
          <NavLink
            to="/student/profile/change-password"
            style={({ isActive }) => {
              return {
                display: "block",
                color: isActive ? colors["primary"][500] : "gray",
                fontFamily: "Montserrat Alternates",
                fontSize: fontSizes["sm"],
                textDecoration: "none",
                borderBottom: isActive ? "solid" : "",
                paddingBottom: 5,
              };
            }}
          >
            Change Password
          </NavLink>

        </HStack>
      </Stack>

      <Box mt={5}>
        <Outlet />
      </Box>
    </ScrollView>
  );
}
