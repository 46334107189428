/*
 *  Dashboard page for Admins
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Box,
  HStack,
  ScrollView,
  Heading,
  Button,
  Pressable,
  useTheme,
  Center,
} from "native-base";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/slices/NavigationSlice";
import {
  getApplicationsMiddleware,
  getAnnouncementMiddleware,
  getStudentsMiddleware,
  getNewApplicantsCountMiddleware,
} from "../../../redux/middlewares/AdminDashboardMiddleware";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";

// icons
import { FiPlus } from "react-icons/fi";
import { BiLinkExternal } from "react-icons/bi";
import { css } from "@emotion/react";

import Calendar from "@ericz1803/react-google-calendar";
import Iframe from "react-iframe";

// TODO: MOVE TO .ENV
const API_KEY = "AIzaSyD8vi4faPnz_boawPmUNgSpzO2Ogm16Ko4";
let calendars = [
  { calendarId: "admin@thegenuineproject.org" },
  {
    calendarId: "en.gh#holiday@group.v.calendar.google.com",
    color: "green",
  },
];

export default function AdminDashBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const { sizes } = useTheme();

  let styles = {
    //you can use object styles (no import required)
    calendar: {
      fontFamily: "Montserrat Alternates",
      borderRadius: sizes[2],
      borderWidth: 0,
    },

    //you can also use emotion's string styles
    today: css`
      /* highlight today by making the text red and giving it a red border */
      color: red;
      background-color: rgba(255, 0, 0, 0.3);
    `,
  };

  // dashboard states
  const [announcementCount, setAnnouncementCount] = React.useState();
  const [studentCount, setStudentCount] = React.useState();
  const [applicantCount, setApplicantCount] = React.useState();

  // set current page
  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Dashboard"));
    dispatch(getApplicationsMiddleware());
    dispatch(getNewApplicantsCountMiddleware());
    dispatch(getAnnouncementMiddleware());
    dispatch(getStudentsMiddleware());
    setApplicantCount(dashboardState.applicants.length);
    setAnnouncementCount(dashboardState.announcements.length);
    setStudentCount(dashboardState.students.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render loading
  if (dashboardState.isDashboardLoading) {
    return (
      <Center h="85vh">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loading,
          }}
          height={200}
          width={200}
        />
        <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
          Loading
        </Text>
      </Center>
    );
  }

  return (
    <ScrollView p={[5, 10]} bg="gray.200">
      {/* announcements */}
      <Box>
        <Heading fontSize={["xl", "2xl"]}>
          Welcome to The Genuine Project
        </Heading>

        <HStack
          flexWrap={"wrap"}
          space={5}
          alignItems="center"
          justifyContent={"flex-start"}
          mt={5}
        >
          <Box
            bg="white"
            p={5}
            w={["100%", "100%", "45%", "33%"]}
            mb={5}
            rounded={sizes[2]}
          >
            <Text fontSize="lg">
              {" "}
              Announcement{" "}
              <Pressable
                top={0.5}
                onPress={() => navigate("/admin/announcements")}
              >
                <BiLinkExternal />
              </Pressable>
            </Text>

            <HStack
              mt={[5, 10]}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Heading size="xl">{announcementCount}</Heading>

              <Button
                leftIcon={<FiPlus />}
                variant="ghost"
                onPress={() => navigate("/admin/announcements/create")}
              >
                add new
              </Button>
            </HStack>
          </Box>

          <Box
            bg="white"
            p={5}
            w={["100%", "100%", "45%", "32%"]}
            mb={5}
            rounded={sizes[2]}
          >
            <Text fontSize="lg">
              Applications{" "}
              <Pressable
                top={0.5}
                onPress={() => navigate("/admin/applications")}
              >
                <BiLinkExternal />
              </Pressable>
            </Text>

            <HStack
              mt={[5, 10]}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Heading size="xl">{applicantCount}</Heading>
            </HStack>
          </Box>

          <Box
            bg="white"
            p={5}
            w={["100%", "100%", "45%", "32%"]}
            mb={5}
            rounded={sizes[2]}
          >
            <Text fontSize="lg">
              Students{" "}
              <Pressable top={0.5} onPress={() => navigate("/admin/students")}>
                <BiLinkExternal />
              </Pressable>
            </Text>

            <HStack
              mt={[5, 10]}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Heading size="xl">{studentCount}</Heading>
            </HStack>
          </Box>
        </HStack>
      </Box>
      <Box w="100%" bg="white" rounded={sizes[2]}>
        <Calendar
          apiKey={API_KEY}
          calendars={calendars}
          // styles={styles}
          showFooter={false}
        />
      </Box>
    </ScrollView>
  );
}
