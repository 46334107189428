import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isCreateAnnouncementLoading: false,
  isEditAnnouncementLoading: false,
  isDashboardLoading: false,

  createAnnouncementMessage: "",
  editAnnouncementMessage: "",
  dashboardMessage: "",

  announcements: [],
  applicants: [],
  students: [],
};

const AdminDashboardSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "AdminDashboardSlice",
  reducers: {
    getDashboardDataStart: (state) => {
      state.ACTION_TYPE = getDashboardDataStart.toString();
      state.isDashboardLoading = true;
    },

    getDashboardDataSuccess: (state, action) => {
      state.ACTION_TYPE = getDashboardDataSuccess.toString();
      state.isDashboardLoading = false;
      state.announcements = action.payload.announcements;
      state.applicants = action.payload.applicants;
      state.students = action.payload.students;
    },

    getDashboardApplicants: (state, action) => {
      state.ACTION_TYPE = getDashboardApplicants.toString();
      state.isDashboardLoading = false;
      state.applicants = action.payload;
    },


    getDashboardAnnouncements: (state, action) => {
      state.ACTION_TYPE = getDashboardAnnouncements.toString();
      state.isDashboardLoading = false;
      state.announcements = action.payload;
    },

    getDashboardStudents: (state, action) => {
      state.ACTION_TYPE = getDashboardStudents.toString();
      state.isDashboardLoading = false;
      state.students = action.payload;
    },

    getDashboardDataError: (state, action) => {
      state.ACTION_TYPE = getDashboardDataError.toString();
      state.isDashboardLoading = false;
      state.dashboardMessage = action.payload.message;
    },

    createAnnouncementStart: (state) => {
      state.ACTION_TYPE = createAnnouncementStart.toString();
      state.isCreateAnnouncementLoading = true;
    },

    createAnnouncementSuccess: (state, action) => {
      state.ACTION_TYPE = createAnnouncementSuccess.toString();
      state.isCreateAnnouncementLoading = false;
      state.createAnnouncementMessage = action.payload.message;
    },

    createAnnouncementError: (state, action) => {
      state.ACTION_TYPE = createAnnouncementError.toString();
      state.isCreateAnnouncementLoading = false;
      state.createAnnouncementMessage = action.payload.message;
    },

    editAnnouncementStart: (state) => {
      state.ACTION_TYPE = editAnnouncementStart.toString();
      state.isEditAnnouncementLoading = true;
    },

    editAnnouncementSuccess: (state, action) => {
      state.ACTION_TYPE = editAnnouncementSuccess.toString();
      state.isEditAnnouncementLoading = false;
      state.editAnnouncementMessage = action.payload.message;
    },

    editAnnouncementError: (state, action) => {
      state.ACTION_TYPE = editAnnouncementError.toString();
      state.isEditAnnouncementLoading = false;
      state.editAnnouncementMessage = action.payload.message;
    },

    resetActionType: (state) => {
      state.ACTION_TYPE = "";
    },
  },
});

export const {
  getDashboardDataStart,
  getDashboardDataSuccess,
  getDashboardDataError,
  resetActionType,
  createAnnouncementStart,
  createAnnouncementSuccess,
  createAnnouncementError,
  editAnnouncementStart,
  editAnnouncementSuccess,
  editAnnouncementError,
  getDashboardAnnouncements,
  getDashboardApplicants,
  getDashboardStudents,
} = AdminDashboardSlice.actions;

export default AdminDashboardSlice.reducer;
