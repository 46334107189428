import React from "react";
import { Center, Text } from "native-base";
import { useDispatch } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";

export default function ForgotPasswordPage() {

    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
      dispatch(setCurrentStudentPage("Pre-Arrival"));
    });

  return (
    <Center h="100vh">
      <Text>PRE ARRIVAL ONBOARDING</Text>
    </Center>
  );
}
