import React from "react";
import { Center, Text } from "native-base";
import { useDispatch } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";

export default function SubmitDocument() {
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
      dispatch(setCurrentStudentPage("Submit Docs"));
    });
  return (
    <Center h="100vh">
      <Text>SUBMIT A DOCUMENT</Text>
    </Center>
  );
}
