import React from "react";
import {
  Center,
  Box,
  Image,
  Stack,
  Button,
  Text,
  Pressable,
  useToast,
  Link,
  HStack,
} from "native-base";
import logo from "../../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";

// lottie
import Lottie from "react-lottie";
import * as emailSent from "../../assets/lottie/email-sent.json";


// firebase
import { sendEmailVerification } from "@firebase/auth";
import { auth } from "../../firebase/firebase-config";

export default function VerifyAccount() {
  const toast = useToast();
  const navigate = useNavigate();

  const email = useLocation()?.state?.email ?? "routed@email.com";

  React.useEffect(() => {
  setTimeout(() => {
    navigate("/login");
  }, 60000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center h="100vh" bg="primary.500">
      <Box
        bg="secondary.50"
        shadow="4"
        w={["90%", "80%", "60%", "50%"]}
        p={[10]}
        rounded="md"
        alignItems={"center"}
      >
        <HStack alignItems={"flex-start"}>
          <Image
            source={logo}
            h={[90, 100]}
            w={[150, 200]}
            resizeMode="contain"
          />
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: emailSent,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          />
        </HStack>

        <Stack space={8} w={["100%", "80%"]} alignItems="center">
          <Box>
            <Text color="white">
              {`An account verification email has been sent to ${email}`}. Once
              your account is verified, you can then log into your account.
            </Text>
            <Text color="white" my={5}>
              If you did not receive an email, please check your spam folder. or
              click on the link below to resend the email.
            </Text>

            <Pressable
              mb={10}
              _pressed={{
                opacity: 0.5,
              }}
              onPress={async () => {
                await sendEmailVerification(auth.currentUser)
                  .then(() => {
                    toast.show({
                      title: "Email sent",
                      description:
                        "A verification email has been sent to your email",
                      placement: "top",
                      status: "success",
                    });
                  })
                  .catch((error) => {
                    toast.show({
                      title: "Email not sent",
                      description: error.message,
                      placement: "top",
                      status: "error",
                    });
                  });
              }}
            >
              <Link _text={{ color: "green.500" }}>
                Resend verification email to {email}
              </Link>
            </Pressable>
          </Box>

          <Button onPress={() => navigate("/login")}>
            LOGIN INTO YOUR ACCOUNT
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
