import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isChangeDpLoading: false,
  isUpdateProfileLoading: false,
  isUpdatePasswordLoading: false,
  isUploadDocumentLoading: false,
  isCreatePostLoading: false,
  isGetPostLoading: false,
  isDeletePostLoading: false,

  changeDpMessage: "",
  updateProfileMessage: "",
  updatePasswordMessage: "",
  uploadDocumentMessage: "",
  createPostMessage: "",
  getPostMessage: "",

  documents: [],
  posts: [],
};

export const StudentSlice = createSlice({
  name: "StudentSlice",
  initialState: INITIAL_STATE,
  reducers: {
    resetActionType: (state) => {
      state.ACTION_TYPE = "";
    },

    changeDpStart: (state) => {
      state.ACTION_TYPE = changeDpStart.toString();
      state.isChangeDpLoading = true;
      state.changeDpMessage = "";
    },

    changeDpSuccess: (state, action) => {
      state.ACTION_TYPE = changeDpSuccess.toString();
      state.isChangeDpLoading = false;
      state.changeDpMessage = action.payload.message;
    },

    changeDpError: (state, action) => {
      state.ACTION_TYPE = changeDpError.toString();
      state.isChangeDpLoading = false;
      state.changeDpMessage = action.payload.message;
    },

    updateProfileStart: (state) => {
      state.ACTION_TYPE = updateProfileStart.toString();
      state.isUpdateProfileLoading = true;
      state.updateProfileMessage = "";
    },

    updateProfileSuccess: (state, action) => {
      state.ACTION_TYPE = updateProfileSuccess.toString();
      state.isUpdateProfileLoading = false;
      state.updateProfileMessage = action.payload.message;
    },

    updateProfileError: (state, action) => {
      state.ACTION_TYPE = updateProfileError.toString();
      state.isUpdateProfileLoading = false;
      state.updateProfileMessage = action.payload.message;
    },

    updatePasswordStart: (state) => {
      state.ACTION_TYPE = updatePasswordStart.toString();
      state.isUpdatePasswordLoading = true;
      state.updatePasswordMessage = "";
    },

    updatePasswordSuccess: (state, action) => {
      state.ACTION_TYPE = updatePasswordSuccess.toString();
      state.isUpdatePasswordLoading = false;
      state.updatePasswordMessage = action.payload.message;
    },

    updatePasswordError: (state, action) => {
      state.ACTION_TYPE = updatePasswordError.toString();
      state.isUpdatePasswordLoading = false;
      state.updatePasswordMessage = action.payload.message;
    },

    uploadDocumentStart: (state) => {
      state.ACTION_TYPE = uploadDocumentStart.toString();
      state.isUploadDocumentLoading = true;
      state.uploadDocumentMessage = "";
    },

    uploadDocumentSuccess: (state, action) => {
      state.ACTION_TYPE = uploadDocumentSuccess.toString();
      state.isUploadDocumentLoading = false;
      state.uploadDocumentMessage = action.payload.message;
    },

    uploadDocumentError: (state, action) => {
      state.ACTION_TYPE = uploadDocumentError.toString();
      state.isUploadDocumentLoading = false;
      state.uploadDocumentMessage = action.payload.message;
    },

    getDocuments: (state, action) => {
      state.ACTION_TYPE = getDocuments.toString();
      state.documents = action.payload;
    },

    createPostStart: (state) => {
      state.ACTION_TYPE = createPostStart.toString();
      state.isCreatePostLoading = true;
      state.createPostMessage = "";
    },

    createPostSuccess: (state, action) => {
      state.ACTION_TYPE = createPostSuccess.toString();
      state.isCreatePostLoading = false;
      state.createPostMessage = action.payload.message;
    },

    createPostError: (state, action) => {
      state.ACTION_TYPE = createPostError.toString();
      state.isCreatePostLoading = false;
      state.createPostMessage = action.payload.message;
    },

    getPostStart: (state) => {
      state.ACTION_TYPE = getPostStart.toString();
      state.isGetPostLoading = true;
    },

    getPosts: (state, action) => {
      state.ACTION_TYPE = getPosts.toString();
      state.posts = action.payload;
      state.isGetPostLoading = false;
    },

    deletePostStart: (state) => {
      state.ACTION_TYPE = deletePostStart.toString();
      state.isDeletePostLoading = true;
    },

    deletePostSuccess: (state, action) => {
      state.ACTION_TYPE = deletePostSuccess.toString();
      state.isDeletePostLoading = false;
    },

    deletePostError: (state, action) => {
      state.ACTION_TYPE = deletePostError.toString();
      state.isDeletePostLoading = false;
    }




  },
});

export const {
  resetActionType,
  changeDpStart,
  changeDpSuccess,
  changeDpError,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  getDocuments,
  createPostStart,
  createPostSuccess,
  createPostError,
  getPostStart,
  getPosts,
  deletePostStart,
  deletePostSuccess,
  deletePostError,
} = StudentSlice.actions;

export default StudentSlice.reducer;
