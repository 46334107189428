import React from "react";
import { Box, Image, Text, HStack, Badge, Center, Button } from "native-base";
import { FiEye, FiRepeat } from "react-icons/fi";


export default function DocumentCard({
  source,
  title,
  renderDescription,
  submited,
  onSubmit,
  onView,
}) {
  return (
    <Box p={5} bg="white" w={["95%", "80%", "60%", "50%"]} rounded="lg">
      <Image
        bg="blue.100"
        w="100%"
        height={[100, 150]}
        rounded="lg"
        source={source}
        resizeMode="cover"
      />
      <HStack my={3} justifyContent="space-between" alignItems={"center"}>
        <Text fontSize="xl" color="darkText" fontWeight={700}>
          {title}
        </Text>

        {submited ? (
          <Badge variant={"solid"} colorScheme="success" rounded={"md"}>
            <Text fontSize="xs" color="white">
              Submited
            </Text>
          </Badge>
        ) : (
          <Badge variant={"solid"} colorScheme="danger">
            <Text fontSize="xs" color="white">
              Not Submited
            </Text>
          </Badge>
        )}
      </HStack>

      {renderDescription}

      <Center mt={5}>
        {submited ? (
          <HStack justifyContent={"center"} space={5}>
            <Button onPress={onView} variant={"subtle"} leftIcon={<FiEye />}>
              VIEW
            </Button>
            <Button
              onPress={onSubmit}
              variant={"subtle"}
              leftIcon={<FiRepeat />}
            >
              CHANGE
            </Button>
          </HStack>
        ) : (
          <Button variant={"subtle"} onPress={onSubmit}>
            SUBMIT
          </Button>
        )}
      </Center>
    </Box>
  );
}
