import React from "react";
import { Center, Text } from "native-base";
import { useDispatch } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";

export default function EditDocument() {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(setCurrentStudentPage("Edit Docs"));
  });
  return (
    <Center h="100vh">
      <Text>EDIT DOCUMENT</Text>
    </Center>
  );
}
