import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Center,
  ScrollView,
  Text,
  Heading,
  HStack,
  Button,
  Stack,
  Box,
  FormControl,
  Select,
  Input,
  IconButton,
  Modal,
  TextArea,
  useToast,
} from "native-base";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";

import { setCurrentPage } from "../../../redux/slices/NavigationSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";

import { FiPlus, FiChevronLeft, FiCalendar } from "react-icons/fi";

// redux
import { useDispatch, useSelector } from "react-redux";
import { editAnnouncementMiddleware } from "../../../redux/middlewares/AdminDashboardMiddleware";
import {
  editAnnouncementSuccess,
  editAnnouncementError,
  resetActionType,
} from "../../../redux/slices/AdminDashboardSlice";

// alert
import Alert from "../../../components/Alert";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";

export default function EditAnnouncements() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const data = location.state.selectedData;

  // console.log(data)


  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Annoucements"));
  });

  const [type, setType] = React.useState(data.type);
  const [title, setTitle] = React.useState(data.title);
  const [description, setDescription] = React.useState(data.description);
  const [link, setLink] = React.useState(data.link);
  const [linkLabel, setLinkLabel] = React.useState(data.linkLabel);
  const [imageUrl, setImageUrl] = React.useState(data.imageUrl);
  const [dueDate, setDueDate] = React.useState(
    new Date(data.dueDate.seconds * 1000)
  );
  const [hasDueDate, setHasDueDate] = React.useState(data?.hasDueDate ?? false);

  // alert
  const [submitAlert, setSubmitAlert] = React.useState(false);

  const DueDatePicker = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant={"outline"} onPress={onClick} ref={ref}>
      {value}
    </Button>
  ));

  const onSubmit = () => {
    const d = {
      type,
      title,
      description,
      link,
      imageUrl,
      hasDueDate,
      dueDate,
    };

    dispatch(editAnnouncementMiddleware(d, data.id));
  };

  // side effects
  React.useEffect(() => {
    if (dashboardState.ACTION_TYPE === editAnnouncementSuccess.toString()) {
      toast.show({
        title: "Success",
        description: dashboardState.editAnnouncementMessage,
        status: "success",
        duration: 2000,
        placement: "top",
      });

      dispatch(resetActionType());
      navigate("/admin/announcements");
    } else if (
      dashboardState.ACTION_TYPE === editAnnouncementError.toString()
    ) {
      toast.show({
        title: "Error",
        description: dashboardState.editAnnouncementMessage,
        status: "danger",
        placement: "top",
      });

      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.ACTION_TYPE]);

  return (
    <ScrollView bg="gray.200" py={[5, 10]}>
      <HStack alignItems="center" space={3} px={[5, 10]}>
        <Button
          leftIcon={<FiChevronLeft size={20} />}
          variant="ghost"
          onPress={() => navigate(-1)}
        />
        <Heading fontSize={["xl", "2xl"]}>EDIT ANNOUNCEMENT</Heading>
      </HStack>

      <Stack
        flexDirection={["column", "column", "row", "row"]}
        space={5}
        my={5}
      >
        <Stack px={10} flex={5} space={5}>
          <FormControl isRequired zIndex={-1}>
            <FormControl.Label>Type</FormControl.Label>
            <Select
              placeholder="Select type"
              bg="white"
              selectedValue={type}
              onValueChange={(value) => setType(value)}
              size="lg"
            >
              <Select.Item value="general" label="General" />
              <Select.Item value="position" label="Position" />
              <Select.Item
                value="preArrivalOnboarding"
                label="Pre-Arrival Onboarding"
              />
            </Select>
          </FormControl>

          <FormControl isRequired zIndex={-1}>
            <FormControl.Label>Title</FormControl.Label>
            <Input
              placeholder="Enter title"
              bg="white"
              size="lg"
              value={title}
              onChangeText={(text) => setTitle(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Description</FormControl.Label>
            <TextArea
              placeholder="Enter description"
              bg="white"
             height={250}
              fontSize={"md"}
     
              value={
                description
              }

              onChangeText={(text) => setDescription(text)}
            />
      
          </FormControl>
        </Stack>
        {/* <Box m={["100px", 0, 5, 5]} /> */}
        <Stack px={10} flex={{ md: 5 }} space={5} zIndex={-1}>
          <FormControl zIndex={-1}>
            <FormControl.Label>Link Label</FormControl.Label>
            <Input
              placeholder="eg. apply for this position"
              bg="white"
              size="lg"
              value={linkLabel}
              onChangeText={(text) => setLinkLabel(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Link</FormControl.Label>
            <Input
              placeholder="eg. https://www.google.com"
              bg="white"
              size="lg"
              value={link}
              onChangeText={(text) => setLink(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Image Url</FormControl.Label>
            <Input
              placeholder="eg. https://www.example.com/image.png"
              bg="white"
              size="lg"
              value={imageUrl}
              onChangeText={(text) => setImageUrl(text)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>Annoucements Options</FormControl.Label>

            <HStack alignItems={"center"} space={2} my={5}>
              <Text> Has Due Date</Text>{" "}
              <Button
                py={1}
                px={3}
                size="sm"
                colorScheme={hasDueDate ? "green" : "red"}
                onPress={() => setHasDueDate(!hasDueDate)}
              >
                {hasDueDate ? "Yes" : "No"}
              </Button>
            </HStack>
          </FormControl>

          {hasDueDate && (
            <FormControl zIndex={100}>
              <FormControl.Label>Due Date</FormControl.Label>
              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                showTimeSelect
                customInput={<DueDatePicker />}
                dateFormat="MMMM d, yyyy h:mm aa"
                className={css`
                  font-family: Montserrat Alternates;
                `}
              />
            </FormControl>
          )}
        </Stack>
      </Stack>

      <Center p={10} zIndex={-10}>
        <Button
          onPress={() => {
            if (!type || !title) {
              toast.show({
                title: "Required fields are missing",
                description: "Please fill in all required fields",
                status: "danger",
                placement: "top",
              });
              return;
            }
            setSubmitAlert(true);
          }}
        >
          Update Announcement
        </Button>
      </Center>

      <Alert
        isOpen={submitAlert}
        onCancel={() => setSubmitAlert(false)}
        onConfirm={() => {
          onSubmit();
          setSubmitAlert(false);
        }}
        title="Edit Announcement"
        message="Are you sure you want to edit this announcement?"
        confirmText="Yes"
        cancelText="No"
      />

      <Modal
        isOpen={dashboardState.isEditAnnouncementLoading}
        bg={"rgba(255,255,255, .5)"}
      >
        <Center p={10} rounded="lg" bg="white">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loading,
            }}
            height={100}
            width={100}
          />
          <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
            Updating Announcement
          </Text>
        </Center>
      </Modal>
    </ScrollView>
  );
}
