// Router
import { BrowserRouter as Router } from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import "bootstrap/dist/css/bootstrap.min.css";



// Redux imports
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <RootLayout />
        </Router>
      </PersistGate>
    </Provider>
  );
}
