import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Center,
  ScrollView,
  Text,
  Heading,
  HStack,
  Button,
  Box,
  IconButton,
  Modal,
  useDisclose,
  Container,
  Link,
} from "native-base";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/slices/NavigationSlice";
import { getAnnouncementMiddleware } from "../../../redux/middlewares/AdminDashboardMiddleware";

// icons
import { FiPlus, FiEdit, FiX } from "react-icons/fi";
import { RiMoreLine } from "react-icons/ri";

// datatable
import DataTable from "react-data-table-component";
import { customStyles } from "../../../customStyles";
import { FilterComponent } from "../../../sharedComponents /FilterComponent";
import { announcementsColumns } from "../../../utils/columns";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";

export default function ViewAnnouncements() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclose();
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // set current page and get snapshot of announcements
  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Announcements"));
    setIsLoading(true);
    dispatch(getAnnouncementMiddleware());
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = dashboardState.announcements?.filter(
    (item) =>
      item.title.toLowerCase().includes(filterText.toLowerCase()) ||
      item.description.toLowerCase().includes(filterText.toLowerCase()) ||
      item.date.toLowerCase().includes(filterText.toLowerCase()) ||
      item.link.toLowerCase().includes(filterText.toLowerCase()) ||
      item.image.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="flex-end" w="100%">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <ScrollView p={[5, 10]} bg="gray.200">
      <HStack alignItems="center" space={3}>
        <Heading fontSize={["xl", "2xl"]}>MANAGE ANNOUNCEMENTS</Heading>
        <Button
          leftIcon={<FiPlus />}
          variant="ghost"
          onPress={() => navigate("/admin/announcements/create")}
        >
          add new
        </Button>
      </HStack>

      <Box mt={5} rounded="md" overflow={"hidden"} bg="white">
        <DataTable
          data={data.sort(
            (a, b) =>
              new Date(b.dateEdited?.seconds * 1000) -
              new Date(a.dateEdited?.seconds * 1000)
          )}
          columns={[
            {
              name: "Actions",
              selector: (row) => row.title,
              sortable: true,
              grow: 1,
              cell: (row, index) => (
                <IconButton
                  icon={<RiMoreLine size={20} />}
                  onPress={() => {
                    setSelectedData(
                      data.find((item) => item.title === row.title)
                    );
                    onOpen();
                  }}
                />
              ),
            },
            ...announcementsColumns,
          ]}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={isLoading}
          progressComponent={
            <Center mb={5}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loading,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={100}
                width={100}
              />
              <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
                Loading...
              </Text>
            </Center>
          }
          responsive
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
        />
      </Box>

      <Modal
        size="full"
        alignItems={"center"}
        justifyContent="center"
        isOpen={isOpen}
        bg={"rgba(0,0,0,0.5)"}
      >
        <Modal.Content
          bg="white"
          w={["90%", "80%", "70%", "50%"]}
          p={10}
          rounded="md"
        >
          <Modal.Header>
            <HStack alignItems="center" justifyContent={"space-between"}>
              <Text fontSize={"xl"}>{selectedData.title}</Text>
              <HStack alignItems="center" justifyContent={"flex-end"}>
                <IconButton
                  icon={<FiEdit />}
                  onPress={() =>
                    navigate(`/admin/announcements/edit/${selectedData.id}`, {
                      state: {
                        selectedData,
                      },
                    })
                  }
                />
                <IconButton
                  zIndex={100}
                  icon={<FiX />}
                  colorScheme="danger"
                  onPress={() => {
                    onClose();
                  }}
                />
              </HStack>
            </HStack>
          </Modal.Header>

          <Modal.Body>
            <Box space={3} alignItems="flex-start" my={4}>
              <Container>
                <Text>{selectedData.description}</Text>
              </Container>
              <Box bg="blue.100" py={1} px={5} rounded="sm" mt={1}>
                <Text fontSize={"xs"}>
                  {selectedData.type === "general"
                    ? "General"
                    : selectedData.type === "position"
                    ? "Position"
                    : selectedData.type === "preArrivalOnboarding" &&
                      "Pre-Arrival Onboarding"}
                </Text>
              </Box>
            </Box>

            {selectedData.imageUrl ? (
              <img
                src={selectedData.imageUrl}
                height="100%"
                width="100%"
                bg="primary.100"
                alt={selectedData.title}
                style={{
                  aspectRatio: "4/3",
                }}
              />
            ) : (
              <Center width="100%" p={10} bg="primary.300">
                <Text>No image available for this announcement.</Text>
              </Center>
            )}

            <Box my={5}>
              <Link href={selectedData.link}>{selectedData.link}</Link>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </ScrollView>
  );
}
