import React from "react";
import { FormControl, Input } from "native-base";

export const CustomInput = ({ label, ...props }) => {
  return (
    <FormControl {...props} >
      <FormControl.Label>{label}</FormControl.Label>
      <Input {...props} bg="white" size="lg" />
    </FormControl>
  );
};


