/*
 * application page -> see all applications
 */

import React from "react";
import {
  ScrollView,
  Text,
  Heading,
  HStack,
  Box,
  IconButton,
  Modal,
  useDisclose,
  Container,
  Pressable,
  Badge,
  Center,
} from "native-base";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/slices/NavigationSlice";
import {
  getApplicationsMiddleware,
  getNewApplicantsCountMiddleware,
} from "../../../redux/middlewares/AdminDashboardMiddleware";
// icons
import { FiX } from "react-icons/fi";
import { RiMoreLine } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";
// datatable
import DataTable from "react-data-table-component";
import { customStyles } from "../../../customStyles";
import { FilterComponent } from "../../../sharedComponents /FilterComponent";
import { applicationsColumns } from "../../../utils/columns";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";
// firebase
import { db } from "../../../firebase/firebase-config";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
} from "@firebase/firestore";

export default function ViewAllApplications() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const { isOpen, onOpen, onClose } = useDisclose();

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // set current page and get snapshot of applicants
  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Applications"));
    setIsLoading(true);
    dispatch(getApplicationsMiddleware());
    dispatch(getNewApplicantsCountMiddleware());
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // read applicant
  const readApplicant = (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    onSnapshot(q, (snapshot) => {
      snapshot.forEach((d) => {
        const documentRef = doc(collection(db, "users"), d.id);
        updateDoc(documentRef, { read: true });
      });
    });
  };


  const data = dashboardState.applicants
    ? dashboardState.applicants.filter(
        (item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(filterText.toLowerCase()) ||
          item.email.toLowerCase().includes(filterText.toLowerCase()) ||
          item.academicStanding
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
          item.currentMajor.toLowerCase().includes(filterText.toLowerCase()) ||
          item.university.toLowerCase().includes(filterText.toLowerCase()) ||
          item.goals.toLowerCase().includes(filterText.toLowerCase()) ||
          item.experience.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];
  // dashboardState?.applicants

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    return (
      <HStack justifyContent="flex-end" w="100%">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </HStack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <ScrollView p={[5, 10]} bg="gray.200">
      <HStack alignItems="center" space={3}>
        <Heading fontSize={["xl", "2xl"]}>Study Abroad Applications</Heading>
      </HStack>

      <Box mt={5} rounded="md" overflow={"hidden"} bg="white">
        <DataTable
          data={data.sort(
            (a, b) =>
              new Date(b.dateApplied?.seconds * 1000) -
              new Date(a.dateApplied?.seconds * 1000)
          )}
          columns={[
            {
              name: "Actions",
              selector: (row) => row.title,
              sortable: true,
              cell: (row, index) => (
                <HStack alignItems="center" space={3}>
                  <IconButton
                    icon={<RiMoreLine size={20} />}
                    onPress={() => {
                      onOpen();
                      setSelectedData(
                        data.find((item) => item.email === row.email)
                      );
                      readApplicant(row.email);
                    }}
                  />
                  {!row.read && <Badge colorScheme="emerald">new</Badge>}
                </HStack>
              ),
            },
            ...applicationsColumns,
          ]}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={isLoading}
          progressComponent={
            <Center mb={5}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loading,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={100}
                width={100}
              />
              <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
                Loading...
              </Text>
            </Center>
          }
          responsive
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
        />
      </Box>

      <Modal
        size="full"
        alignItems={"center"}
        justifyContent="center"
        isOpen={isOpen}
        bg={"rgba(0,0,0,0.5)"}
      >
        <Box
          bg="white"
          w={["90%", "80%", "70%", "50%"]}
          p={10}
          rounded="md"
          h="70vh"
        >
          <Text>
            {new Date(selectedData?.dateApplied?.seconds * 1000).toDateString()}
          </Text>
          <HStack alignItems="center" justifyContent={"space-between"}>
            <Text fontSize={"xl"} fontWeight="bold">
              {selectedData.name}
              <Pressable
                onPress={() => {
                  // if resume is not empty open file in new window
                  if (selectedData.resume) {
                    window.open(selectedData.resume, "_blank");
                  }
                }}
              >
                <Text fontWeight={"light"} color="blue.400">
                  resume
                  <BiLinkExternal
                    size={15}
                    style={{
                      marginBottom: -2,
                    }}
                  />
                </Text>
              </Pressable>
            </Text>
            <HStack alignItems="center" justifyContent={"flex-end"}>
              <IconButton
                zIndex={100}
                icon={<FiX />}
                colorScheme="danger"
                onPress={() => {
                  onClose();
                }}
              />
            </HStack>
          </HStack>
          <ScrollView>
            <Container my={4}>
              <Text fontWeight={"bold"}>Phone Number</Text>
              <Text>{selectedData.phoneNumber}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>Email</Text>
              <Text>{selectedData.email}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>Academic Standing</Text>
              <Text>{selectedData.academicStanding}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>Current Major</Text>
              <Text>{selectedData.currentMajor}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>University</Text>
              <Text>{selectedData.university}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>Goals</Text>
              <Text>{selectedData.goals}</Text>
            </Container>

            <Container my={4}>
              <Text fontWeight={"bold"}>Experience</Text>
              <Text>{selectedData.experience}</Text>
            </Container>
          </ScrollView>
        </Box>
      </Modal>
    </ScrollView>
  );
}
