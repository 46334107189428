import React from "react";
import { Center, ScrollView, Text, useTheme, Box } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";
import { getUser } from "../../../redux/middlewares/StudentMiddleware";

import { css } from "@emotion/react";

// calendar
import Calendar from "@ericz1803/react-google-calendar";

import Iframe from "react-iframe";

// TODO: MOVE TO .ENV
const API_KEY = "AIzaSyD8vi4faPnz_boawPmUNgSpzO2Ogm16Ko4";
let calendars = [
  { calendarId: "admin@thegenuineproject.org" },
  {
    calendarId: "en.gh#holiday@group.v.calendar.google.com",
    color: "green",
  },
];

export default function CalendarPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  const { sizes } = useTheme();

  let styles = {
    //you can use object styles (no import required)
    calendar: {
      fontFamily: "Montserrat Alternates",
      borderRadius: sizes[2],
      borderWidth: 0,
    },

    //you can also use emotion's string styles
    today: css`
      /* highlight today by making the text red and giving it a red border */
      color: red;
      background-color: rgba(255, 0, 0, 0.3);
    `,
  };

  React.useLayoutEffect(() => {
    dispatch(getUser(user.id));
    dispatch(setCurrentStudentPage("Calendar"));
  }, []);

  return (
    <ScrollView px={[2, 10]} py={[5, 10]}>
      <Box w="100%" h="90vh" bg="white" rounded={sizes[2]}>
        <Calendar
          apiKey={API_KEY}
          calendars={calendars}
          // styles={styles}
          showFooter = {false}
        />

        {/* <Iframe
          url="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Etc%2FGMT&showTabs=1&showPrint=0&showDate=1&showNav=1&showTitle=0&showCalendars=0&showTz=1&mode=WEEK&src=YWRtaW5AdGhlZ2VudWluZXByb2plY3Qub3Jn&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4uZ2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%234285F4"
          //  add emotion css
          style={css`
            .st-bg {
              background: black;
            }
            .st-bg-today {
              background: yellow;
              border-left: 1px solid yellow;
              border-right: 1px solid yellow;
            }
            .st-dtitle {
              background: white;
            }
            .view-cap,
            .view-container-border {
              background-color: black;
            }
            .mv-daynames-table {
              color: white;
              background: black;
            }
            .mv-event-container {
              border-top: 1px solid black;
            }
          `}
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          title="Calendar"
        /> */}
      </Box>
    </ScrollView>
  );
}
