import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// pages
import LoginPage from "../pages/auth/Login";
import SignupPage from "../pages/auth/Signup";
import ForgotPasswordPage from "../pages/auth/ForgotPassword";
import VerificationPage from "../pages/auth/VerifyAccount";



export default function AuthLayout() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/verify-account" element={<VerificationPage />} />
      {/* default redirect */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
