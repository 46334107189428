export const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent",
      // zIndex:'-1'
    },
  },
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderColor: "#093A59",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "transparent",
      },
      fontFamily: "Montserrat Alternates",
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "transparent",
        borderRadius: 0,
      },
      fontFamily: "Montserrat Alternates",
    },
  },
  rows: {
    style: {
      "&:hover": {
        borderRadius: 0,
      },
    },
  },

  pagination: {
    style: {
      fontFamily: "Montserrat Alternates",
    },
  },
};
