import React from "react";
import { Center, Text } from "native-base";
import { useDispatch } from "react-redux";
import { setCurrentStudentPage } from "../../../redux/slices/NavigationSlice";

export default function ViewDocuments() {

    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
      dispatch(setCurrentStudentPage("Docs"));
    });

  return (
    <Center h="100vh">
      <Text>DCOUMENTS TABLE</Text>
    </Center>
  );
}
