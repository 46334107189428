import {
  changeDpStart,
  changeDpSuccess,
  changeDpError,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  getDocuments,
  createPostStart,
  createPostSuccess,
  createPostError,
  getPosts,
  getPostStart,
  deletePostStart,
  deletePostSuccess,
  deletePostError,
} from "../slices/StudentSlice";

import { updateUser } from "../slices/AuthenticationSlice";

// firebase
import { db, auth } from "../../firebase/firebase-config";
import {
  collection,
  onSnapshot,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
} from "@firebase/firestore";

import {
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  getAuth,
  signOut,
} from "@firebase/auth";

// validate user account -> handles validation of user account
export const changeDpMiddleware = (dp, id) => {
  // update user account in firebase
  return async (dispatch) => {
    dispatch(changeDpStart());
    try {
      const appUser = doc(db, "app-users", id);
      await updateDoc(appUser, { profilePicture: dp }).then(async () => {
        await dispatch(getUser(id));
        dispatch(
          changeDpSuccess({
            message: "Profile picture changed successfully",
          })
        );
      });
    } catch (error) {
      dispatch(changeDpError({ message: error.code }));
    }
  };
};

// update user profile -> handles updating of user profile
export const updateProfileMiddleware = (profile, id) => {
  // update user account in firebase
  return async (dispatch) => {
    dispatch(updateProfileStart());
    try {
      const appUser = doc(db, "app-users", id);
      await updateDoc(appUser, profile).then(async () => {
        await dispatch(getUser(id));
        dispatch(
          updateProfileSuccess({
            message: "Profile updated successfully",
          })
        );
      });
    } catch (error) {
      dispatch(updateProfileError({ message: error.code }));
    }
  };
};

// get user -> handles getting of user in firebase
export const getUser = (id) => async (dispatch) => {
  const q = query(collection(db, "app-users"), where("id", "==", id));
  try {
    onSnapshot(q, (snapshot) => {
      const user = [];
      snapshot.forEach((d) => {
        user.push({ ...d.data(), id: d.id });
      });

      dispatch(updateUser(user[0]));
    });
  } catch (error) {
    console.log(error.message);
  }
};

// update password
export const updatePasswordMiddleware = (oldPassword, password) => {
  console.log(oldPassword, password);
  return async (dispatch) => {
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    dispatch(updatePasswordStart());

    return reauthenticateWithCredential(user, credential)
      .then(() => {
        // User re-authenticated.
        updatePassword(user, password)
          .then(() => {
            dispatch(
              updatePasswordSuccess({
                message: "Password updated successfully",
              })
            );
          })
          .catch((error) => {
            console.log(error.code);
            dispatch(updatePasswordError({ message: error.code }));
          });
      })
      .catch((error) => {
        // An error ocurred
        console.log(error.code);
        dispatch(updatePasswordError({ message: error.code }));
      });
  };
};

// upload document to firebase
export const uploadDocumentMiddleware = (id, document) => {
  console.log(id, document);
  return async (dispatch) => {
    dispatch(uploadDocumentStart());
    try {
      // write a subcollection documents and add uploaded file
      const userCollection = collection(db, "app-users");
      const user = doc(userCollection, id);
      const documentCollection = doc(user, "documents", id);

      await setDoc(documentCollection, document, { merge: true }).then(() => {
        dispatch(
          uploadDocumentSuccess({
            message: "Document uploaded successfully",
          })
        );
      });
    } catch (error) {
      console.log(error.code);
      dispatch(uploadDocumentError({ message: error.code }));
    }
  };
};

// get documents
export const getDocumentsMiddleware = (id) => async (dispatch) => {
  const docRef = doc(db, "app-users", id);
  const q = query(collection(docRef, "documents"));

  try {
    onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });

      const obj = data.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

      dispatch(getDocuments(obj));
    });
  } catch (error) {
    console.log(error.code);
  }
};

// createPostMiddleware
export const createPostMiddleware = (post) => async (dispatch) => {
  console.log(post);
  try {
    dispatch(createPostStart());
    await addDoc(collection(db, "posts"), post).then(() => {
      dispatch(
        createPostSuccess({
          message: "Post created successfully",
        })
      );
    });
  } catch (error) {
    console.log(error.message);
    dispatch(createPostError({ message: error.message }));
  }
};

// editPostMiddleware 
export const editPostMiddleware = (id, post) => async (dispatch) => {
  try {
    dispatch(createPostStart());
    await updateDoc(doc(db, "posts", id), post).then(() => {
      dispatch(
        createPostSuccess({
          message: "Post updated successfully",
        })
      );
    });
  } catch (error) {
    console.log(error.code);
    dispatch(createPostError({ message: error.code }));
  }
}

// delete post middleware
export const deletePostMiddleware = (id) => async (dispatch) => {
  try {
    dispatch(deletePostStart());
    await deleteDoc(doc(db, "posts", id)).then(() => {
      dispatch(
        deletePostSuccess({
          message: "Post deleted successfully",
        })
      );
    });
  } catch (error) {
    console.log(error.code);
    dispatch(deletePostError({ message: error.code }));
  }
}

//  getPostsMiddleware
export const getPostsMiddleware = () => async (dispatch) => {
  // listen to posts collection
  const q = query(collection(db, "posts"));

  try {
    dispatch(getPostStart());
    onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
      // push data and id to array
        data.push({ ...doc.data(), id: doc.id });
      
      });
      console.log(data, "posts");

      dispatch(
        getPosts(
          data.sort(
            (a, b) =>
              new Date(b.createdAt.seconds * 1000) -
              new Date(a.createdAt.seconds * 1000)
          )
        )
      );
    });
  } catch (error) {
    console.log(error.code);
  }
};
