import React from "react";
import { Modal, Center, Text } from "native-base";

// lottie
import Lottie from "react-lottie";
import * as loadingLottie from "../assets/lottie/loading.json";

export default function Loading({ isLoading, loadingText }) {
  return (
    <Modal isOpen={isLoading} bg={"rgba(255,255,255, .5)"}>
      <Center p={10} rounded="lg" bg="white">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingLottie,
          }}
          height={100}
          width={100}
        />
        <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
          {loadingText}
        </Text>
      </Center>
    </Modal>
  );
}
