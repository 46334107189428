export const announcementsColumns = [
  {
    name: "title",
    selector: (row) => row.title,
    sortable: true,
    grow: 2,
  },
  {
    name: "Link",
    selector: (row) => row.link,
    sortable: true,
    grow: 3,
  },
  {
    name: "Image",
    selector: (row) => row.imageUrl,
    sortable: true,
    grow: 3,
  },
];

export const applicationsColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    grow: 1,
  },
  {
    name: "Phone Number",
    selector: (row) => row.phoneNumber,
    sortable: true,
    grow: 1,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    grow: 1,
  },
  {
    name: "Academic Standing",
    selector: (row) => row.academicStanding,
    sortable: true,
    grow: 1,
  },
  {
    name: "Current Major",
    selector: (row) => row.currentMajor,
    sortable: true,
    grow: 1,
  },
  {
    name: "University",
    selector: (row) => row.university,
    sortable: true,
    grow: 1,
  },
];

export const studentsColumns = [
  {
    name: "Name",
    selector: (row) => `${row.firstname} ${row.middlename}  ${row.lastname}`,
    sortable: true,
  },
  {
    name: "Phone Number",
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  // {
  //   name: "Academic Standing",
  //   selector: (row) => row.academicStanding,
  //   sortable: true,
  // },
  // {
  //   name: "Current Major",
  //   selector: (row) => row.currentMajor,
  //   sortable: true,
  // },
  {
    name: "University",
    selector: (row) => row.university,
    sortable: true,
  },
];
