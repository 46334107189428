import React from "react";
import {
  Center,
  Text,
  ScrollView,
  Avatar,
  IconButton,
  Modal,
  Button,
  Box,
  useToast,
  Stack,
  FormControl,
  Select,
  CheckIcon,
} from "native-base";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  changeDpError,
  changeDpSuccess,
  resetActionType,
  updateProfileSuccess,
  updateProfileError,
} from "../../../redux/slices/StudentSlice";
import {
  changeDpMiddleware,
  updateProfileMiddleware,
} from "../../../redux/middlewares/StudentMiddleware";

// firebase
import { storage } from "../../../firebase/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";

// icons
import { MdAddPhotoAlternate } from "react-icons/md";

// custom components
import Loading from "../../../sharedComponents /Loading";
import PickImageModal from "../../../components/modals/PickImageModal";
import { CustomInput as Input } from "../../../components/FormComponents";
import Alert from "../../../components/Alert";

// firebase
import { db } from "../../../firebase/firebase-config";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  doc,
  where,
  collectionGroup,
} from "@firebase/firestore";

export default function EditAccount() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const studentState = useSelector((state) => state.student);
  const toast = useToast();


  const [showChangeDp, setShowChangeDp] = React.useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState();
  const [formData, setFormData] = React.useState({
    firstname: user.firstname,
    middlename: user.middlename,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    university: user.university,
    academicStanding: user.academicStanding,
    currentMajor: user.currentMajor,
    dateEdited: new Date(),
  });

  const changeDp = async (file) => {
    if (!file) return;

    const storageRef = ref(
      storage,
      `/profilePictures/${user?.firstname}-${user?.middlename}-${user?.lastname}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          dispatch(changeDpMiddleware(url, user.id));
          setLoading(false);
          setShowChangeDp(false);
        });
      }
    );
  };

  const updateProfile = () => {
    dispatch(updateProfileMiddleware(formData, user.id));
  };


  // side effect of changing dp
  React.useEffect(() => {
    if (studentState.ACTION_TYPE === changeDpSuccess.toString()) {
      setShowChangeDp(false);
      toast.show({
        title: "Success",
        description: studentState.changeDpMessage,
        status: "success",
        placement: "top",
      });
      setFile(null);
      dispatch(resetActionType());
    } else if (studentState.ACTION_TYPE === changeDpError.toString()) {
      setShowChangeDp(false);
      toast.show({
        title: "Error",
        description: studentState.changeDpMessage,
        status: "danger",
        placement: "top",
      });
      setFile(null);
      dispatch(resetActionType());
    } else if (studentState.ACTION_TYPE === updateProfileSuccess.toString()) {
      setShowUpdateAlert(false);
      toast.show({
        title: "Success",
        description: studentState.updateProfileMessage,
        status: "success",
        placement: "top",
      });
      dispatch(resetActionType());
    } else if (studentState.ACTION_TYPE === updateProfileError.toString()) {
      setShowUpdateAlert(false);
      toast.show({
        title: "Error",
        description: studentState.updateProfileMessage,
        status: "danger",
        placement: "top",
      });
      dispatch(resetActionType());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentState.ACTION_TYPE]);

  return (
    <Box>
      {/* profile picture */}
      <Center>
        <Box>
          <Avatar
            size="2xs"
            bg="primary.200"
            source={{ uri: user?.profilePicture ? user?.profilePicture : "" }}
          >
            <Text fontSize="2xl" color="white" fontWeight={"bold"}>
              {user?.firstname?.charAt(0).toUpperCase()}
              {user?.middlename?.charAt(0).toUpperCase()}
              {user?.lastname?.charAt(0).toUpperCase()}
            </Text>
          </Avatar>
          <IconButton
            variant="solid"
            colorScheme="primary"
            icon={
              <MdAddPhotoAlternate
                size={30}
                style={{
                  color: "whitesmoke",
                }}
              />
            }
            position="absolute"
            bottom="0"
            right="10"
            onPress={() => {
              setShowChangeDp(true);
            }}
          />
        </Box>
        {/* change image modal  */}
        <PickImageModal
          title="Change Profile Picture"
          isOpen={showChangeDp}
          visible={showChangeDp}
          message="Please select an image to use as your profile picture"
          isLoading={loading}
          isLoadingText={` uploading ${progress}%`}
          onCancel={() => {
            setShowChangeDp(false);
          }}
          onConfirm={() => {
            changeDp(file);
          }}
          onClose={() => {
            setShowChangeDp(false);
          }}
          onPick={(e) => {
            setFile(e.target.files[0]);
          }}
        />
      </Center>

      <Center>
        <Stack
          my={10}
          px={10}
          flex={5}
          space={5}
          w={["95%", "80%", "60%", "50%"]}
        >
          <Input
            label="First Name"
            placeholder="first name"
            value={formData?.firstname}
            onChangeText={(text) => {
              setFormData({ ...formData, firstname: text });
            }}
          />

          <Input
            label="Middle Name"
            placeholder="middle name"
            value={formData?.middlename}
            onChangeText={(text) => {
              setFormData({ ...formData, middlename: text });
            }}
          />

          <Input
            label="Last Name"
            placeholder="last name"
            value={formData?.lastname}
            onChangeText={(text) => {
              setFormData({ ...formData, lastname: text });
            }}
          />

          <Input
            label="Email"
            placeholder="email"
            value={formData?.email}
            onChangeText={(text) => {
              setFormData({ ...formData, email: text });
            }}
          />

          <Input
            label="Phone Number"
            placeholder="phone number"
            value={formData?.phone}
            onChangeText={(text) => {
              setFormData({ ...formData, phone: text });
            }}
          />

          <Input
            label="University"
            placeholder="university"
            value={formData?.university}
            onChangeText={(text) => {
              setFormData({ ...formData, university: text });
            }}
          />

          <FormControl>
            <FormControl.Label>
              Academic Standing in Fall 2022
            </FormControl.Label>
            <Select
              selectedValue={formData?.academicStanding}
              minWidth="200"
              fontSize="md"
              bg="white"
              accessibilityLabel="Choose your academic standing"
              placeholder="Choose your academic standing"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                setFormData({ ...formData, academicStanding: itemValue });
              }}
            >
              <Select.Item label="Freshman" value="Freshman" />
              <Select.Item label="Sophomore" value="Sophomore" />
              <Select.Item label="Junior" value="Junior" />
              <Select.Item label="Senior" value="Senior" />
            </Select>
          </FormControl>

          <Input
            label="Current Major"
            placeholder="Current Major"
            value={formData?.currentMajor}
            onChangeText={(text) => {
              setFormData({ ...formData, currentMajor: text });
            }}
          />

          <Button mt={4} onPress={() => setShowUpdateAlert(true)}>
            UPDATE PROFILE
          </Button>
        </Stack>
      </Center>

      {/* loading modal for profile picture  */}
      <Loading
        isLoading={studentState.isChangeDpLoading}
        loadingText=" Updating Profile Image"
      />

      {/* loading modal for profile update  */}
      <Loading
        isLoading={studentState.isUpdateProfileLoading}
        loadingText=" Updating Profile"
      />

      {/* update alert */}
      <Alert
        isOpen={showUpdateAlert}
        title="Update Profile"
        message="Are you sure you want to update your profile?"
        cancelText={"Cancel"}
        confirmText={"Update"}
        onCancel={() => {
          setShowUpdateAlert(false);
        }}
        onConfirm={() => {
          setShowUpdateAlert(false);
          updateProfile();
        }}
      />
    </Box>
  );
}
