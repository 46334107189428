import React from "react";
import {
  Text,
  Pressable,
  Image,
  Menu,
  HStack,
  Stack,
  Box,
  Badge,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { FiMenu, FiLogOut } from "react-icons/fi";

// logo
import logo from "../assets/logo-sm.png";

import { useSelector, useDispatch } from "react-redux";

import { logoutMiddleware } from "../redux/middlewares/AuthenticationMiddleware";


export default function AdminNavbar(props) {
  const { menuData = [] } = props;
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { currentPage } = useSelector((state) => state.navigation);
  const { newApplicantsCount } = useSelector((state) => state.navigation);
  const dispatch = useDispatch();

  return (
    <HStack
      p={5}
      bg="primary.500"
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <HStack space={5} alignItems="center">
        <Menu
          offset={23}
          right={10}
          p={5}
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <FiMenu size={25} color="white" />
              </Pressable>
            );
          }}
        >
          {menuData.map((item, index) => (
            <Menu.Item
              px={"1rem"}
              py={".5rem"}
              rounded="md"
              my={1}
              ml={1}
              _hover={{
                backgroundColor:
                  index === currentIndex ? "primary.500" : "gray.200",
              }}
              bg={index === currentIndex ? "primary.500" : "transparent"}
              flexDir="row"
              alignItems={"center"}
              key={index}
              onPress={() => {
                navigate(item.link);
                setCurrentIndex(index);
              }}
            >
              <Text color={index === currentIndex ? "white" : "primary.500"}>
                {item.icon}
              </Text>
              <Text
                color={index === currentIndex ? "white" : "primary.500"}
                ml={3}
                mb={1}
                fontWeight="medium"
              >
                {item.name}
              </Text>

              {item.name === "Applications" && newApplicantsCount > 0 && (
                <Badge ml={2} mb={1} colorScheme="green" rounded="lg">
                  <Text fontSize="xs">{newApplicantsCount}</Text>
                </Badge>
              )}
            </Menu.Item>
          ))}
          <Pressable
            rounded={"md"}
            py={".5rem"}
            my={2}
            mx={1}
            bg="red.500"
            flexDir="row"
            alignItems={"center"}
            justifyContent="center"
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => dispatch(logoutMiddleware())}
          >
            <FiLogOut color="white" size={20} />
            <Stack>
              <Text color="white" ml={2}>
                Logout
              </Text>
            </Stack>
          </Pressable>
        </Menu>

        <Text color="white" fontSize="lg">
          {currentPage}
        </Text>
      </HStack>

     
        <Image
          source={logo}
          alt="logo"
          resizeMode="contain"
          h={7}
          w={7}
          tintColor={"white"}
        />

    </HStack>
  );
}
