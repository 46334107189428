import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";

// TODO: Themeing and styling
const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },

  colors: {
    primary: {
      50: "rgba(10, 58, 90, .1)",
      100: "rgba(10, 58, 90, .2)",
      200: "rgba(10, 58, 90, .3)",
      300: "rgba(10, 58, 90, .4)",
      400: "rgba(10, 58, 90, .5)",
      500: "rgba(10, 58, 90, 6)",
      600: "rgba(10, 58, 90, .5)",
      700: "rgba(10, 58, 90, .8)",
      800: "rgba(10, 58, 90, .9)",
      900: "rgba(10, 58, 90, 1)",
    },
    secondary: {
      50: "rgba(18, 118, 214, .1)",
      100: "rgba(18, 118, 214, .2)",
      200: "rgba(18, 118, 214, .3)",
      300: "rgba(18, 118, 214, .4)",
      400: "rgba(18, 118, 214, .5)",
      500: "rgba(18, 118, 214, .6)",
      600: "rgba(18, 118, 214, .7)",
      700: "rgba(18, 118, 214, .8)",
      800: "rgba(18, 118, 214, .9)",
      900: "rgba(18, 118, 214, 1)",
    },
    service: {
      200: "rgba(229, 201, 128, .3)",
      500: "rgba(229, 201, 128, .7)",
      600: "rgba(229, 201, 128, .7)",
    },
    turquoise: {
      50: "rgba(18, 199, 214, .1)",
      100: "rgba(18, 199, 214, .2)",
      200: "rgba(18, 199, 214, .3)",
      300: "rgba(18, 199, 214, .4)",
      400: "rgba(18, 199, 214, .5)",
      500: "rgba(18, 199, 214, .6)",
      600: "rgba(18, 199, 214, .7)",
      700: "rgba(18, 199, 214, .8)",
      800: "rgba(18, 199, 214, .9)",
      900: "rgba(18, 199, 214, 1)",
    },
  },

  fonts: {
    heading: "Montserrat Alternates",
    body: "Montserrat Alternates",
    mono: "Julius Sans One",
  },
  components: {
    Text: {
      baseStyle: (props) => {
        return {
          color: "#37393C",
          fontWeight: "400",
        };
      },
    },
    Heading: {
      baseStyle: (props) => {
        return {
          color: "#37393C",
          fontWeight: "bold",
        };
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
