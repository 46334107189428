export const DOC_TYPE = {
  PASSPORT: "passport",
  VISA: "visa",
  YELLOW_FEVER: "yellowFever",
  COVID: "covid",
  FLIGHT_DETAILS: "flightDetails",
  MEDICAL_INFO: "medicalInfo",
};

export const ANNOUCEMENT_TYPE = {
  GENERAL: "general",
  POSITION: "position",
  PRE_ARRIVAL_ONBORDING: "preArrivalOnboarding",
};
