import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Center,
  ScrollView,
  Text,
  Heading,
  HStack,
  Button,
  Stack,
  Box,
  FormControl,
  Select,
  Input,
  IconButton,
  useToast,
  Modal,
  TextArea,
} from "native-base";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";

import { FiPlus, FiChevronLeft, FiCalendar } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import { createAnnouncementMiddleware } from "../../../redux/middlewares/AdminDashboardMiddleware";
import {
  createAnnouncementSuccess,
  createAnnouncementError,
  resetActionType,
} from "../../../redux/slices/AdminDashboardSlice";
import { setCurrentPage } from "../../../redux/slices/NavigationSlice";

// alert
import Alert from "../../../components/Alert";

// lottie
import Lottie from "react-lottie";
import * as loading from "../../../assets/lottie/loading.json";

export default function EditAnnouncements() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardState = useSelector((state) => state.adminDashboard);
  const toast = useToast();

  React.useLayoutEffect(() => {
    dispatch(setCurrentPage("Annoucements"));
  });

  const [type, setType] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [link, setLink] = React.useState("");
  const [linkLabel, setLinkLabel] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");
  const [dueDate, setDueDate] = React.useState(new Date());

  // position options
  const [hasDueDate, setHasDueDate] = React.useState(false);

  // alert
  const [submitAlert, setSubmitAlert] = React.useState(false);

  const DueDatePicker = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant={"outline"} onPress={onClick} ref={ref}>
      {value}
    </Button>
  ));

  const onSubmit = () => {
    const data = {
      type,
      title,
      description:
        description &&
        draftToMarkdown(convertToRaw(description.getCurrentContent())),
      link,
      linkLabel,
      imageUrl,
      hasDueDate,
      dueDate,
    };

    dispatch(createAnnouncementMiddleware(data));
  };

  // console.log(description, 'wtf')

  // side effects
  React.useEffect(() => {
    if (dashboardState.ACTION_TYPE === createAnnouncementSuccess.toString()) {
      toast.show({
        title: "Announcement created successfully",
        status: "success",
        duration: 2000,
        placement: "top",
      });

      dispatch(resetActionType());
      navigate("/admin/announcements");
    } else if (
      dashboardState.ACTION_TYPE === createAnnouncementError.toString()
    ) {
      toast.show({
        title: "Error creating announcement",
        status: "danger",
        placement: "top",
      });

      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.ACTION_TYPE]);

  console.log(
    description &&
      draftToMarkdown(convertToRaw(description.getCurrentContent()))
  );

  return (
    <ScrollView bg="gray.200" py={[5, 10]}>
      <HStack alignItems="center" space={3} px={[5, 10]}>
        <Button
          leftIcon={<FiChevronLeft size={20} />}
          variant="ghost"
          onPress={() => navigate(-1)}
        />
        <Heading fontSize={["xl", "2xl"]}>CREATE NEW ANNOUNCEMENT</Heading>
      </HStack>

      <Stack
        flexDirection={["column", "column", "row", "row"]}
        space={5}
        my={5}
      >
        <Stack px={10} flex={5} space={5}>
          <FormControl isRequired zIndex={-1}>
            <FormControl.Label>Type</FormControl.Label>
            <Select
              placeholder="Select type"
              bg="white"
              selectedValue={type}
              onValueChange={(value) => setType(value)}
              size="lg"
            >
              <Select.Item value="general" label="General" />
              <Select.Item value="position" label="Position" />
              <Select.Item
                value="preArrivalOnboarding"
                label="Pre-Arrival Onboarding"
              />
            </Select>
          </FormControl>

          <FormControl isRequired zIndex={-1}>
            <FormControl.Label>Title</FormControl.Label>
            <Input
              placeholder="Enter title"
              bg="white"
              size="lg"
              value={title}
              onChangeText={(text) => setTitle(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Description</FormControl.Label>
            <TextArea
              placeholder="Enter description"
              bg="white"
              size="lg"
              isDisabled
              value={
                description &&
                draftToMarkdown(convertToRaw(description.getCurrentContent()))
              }
            />
            <Box
              bg="white"
              overflow="hidden"
              borderBottomLeftRadius={"md"}
              borderBottomRightRadius="md"
            >
              <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(text) => setDescription(text)}
              />
            </Box>
          </FormControl>
        </Stack>
        {/* <Box m={["100px", 0, 5, 5]} /> */}
        <Stack px={10} flex={{ md: 5 }} space={5} zIndex={-1}>
          <FormControl zIndex={-1}>
            <FormControl.Label>Link Label</FormControl.Label>
            <Input
              placeholder="eg. apply for this position"
              bg="white"
              size="lg"
              value={linkLabel}
              onChangeText={(text) => setLinkLabel(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Link</FormControl.Label>
            <Input
              placeholder="eg. https://www.google.com"
              bg="white"
              size="lg"
              value={link}
              onChangeText={(text) => setLink(text)}
            />
          </FormControl>

          <FormControl zIndex={-1}>
            <FormControl.Label>Image Url</FormControl.Label>
            <Input
              placeholder="eg. https://www.example.com/image.png"
              bg="white"
              size="lg"
              value={imageUrl}
              onChangeText={(text) => setImageUrl(text)}
            />
          </FormControl>
          <FormControl my={5}>
            <FormControl.Label>Annoucements Options</FormControl.Label>
            <HStack alignItems={"center"} space={2}>
              <Text> Has Due Date</Text>{" "}
              <Button
                py={1}
                px={3}
                size="sm"
                colorScheme={hasDueDate ? "green" : "red"}
                onPress={() => setHasDueDate(!hasDueDate)}
              >
                {hasDueDate ? "Yes" : "No"}
              </Button>
            </HStack>
          </FormControl>

          {hasDueDate && (
            <FormControl>
              <FormControl.Label>Due Date</FormControl.Label>

              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                showTimeSelect
                customInput={<DueDatePicker />}
                dateFormat="MMMM d, yyyy h:mm aa"
                className={css`
                  font-family: Montserrat Alternates;
                `}
              />
            </FormControl>
          )}
        </Stack>
      </Stack>
      <Center p={10} zIndex={-1}>
        <Button
          onPress={() => {
            if (!type || !title) {
              toast.show({
                title: "Required fields are missing",
                description: "Please fill in all required fields",
                status: "danger",
                placement: "top",
              });
              return;
            }
            setSubmitAlert(true);
          }}
        >
          Create Announcement
        </Button>
      </Center>

      <Alert
        isOpen={submitAlert}
        onCancel={() => setSubmitAlert(false)}
        onConfirm={() => {
          onSubmit();
          setSubmitAlert(false);
        }}
        title="Create Announcement"
        message="Are you sure you want to create this announcement?"
        confirmText="Yes"
        cancelText="No"
      />

      <Modal
        isOpen={dashboardState.isCreateAnnouncementLoading}
        bg={"rgba(255,255,255, .5)"}
      >
        <Center p={10} rounded="lg" bg="white">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loading,
            }}
            height={100}
            width={100}
          />
          <Text fontWeight="medium" fontSize={"sm"} color="#093A59">
            Creating Announcement
          </Text>
        </Center>
      </Modal>
    </ScrollView>
  );
}
