import React from "react";
import {
  Center,
  Text,
  ScrollView,
  Avatar,
  IconButton,
  Modal,
  Button,
  Box,
  useToast,
  Stack,
  FormControl,
  Select,
  CheckIcon,
} from "native-base";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  resetActionType,
  updatePasswordError,
  updatePasswordSuccess,
} from "../../../redux/slices/StudentSlice";
import { updatePasswordMiddleware } from "../../../redux/middlewares/StudentMiddleware";

// firebase
import { storage } from "../../../firebase/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";

// icons
import { MdAddPhotoAlternate } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";

// custom components
import Loading from "../../../sharedComponents /Loading";
import PickImageModal from "../../../components/modals/PickImageModal";
import { CustomInput as Input } from "../../../components/FormComponents";
import Alert from "../../../components/Alert";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const studentState = useSelector((state) => state.student);
  const toast = useToast();

  const [oldpassword, setOldPassword] = React.useState("");
  const [newpassword, setNewPassword] = React.useState("");
  const [confirmpassword, setConfirmPassword] = React.useState("");

  const [showOldPassword, setShowOldPassword] = React.useState(true);
  const [showNewPassword, setShowNewPassword] = React.useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(true);

  const [showAlert, setShowAlert] = React.useState(false);

  const updatePassword = () => {
    dispatch(
      updatePasswordMiddleware(
        oldpassword,
        confirmpassword,
      )
    );
  };

  // side effects for password change
  React.useEffect(() => {
    if (studentState.ACTION_TYPE === updatePasswordSuccess.toString()) {
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      toast.show({
        title: "Success",
        description: studentState.updatePasswordMessage,
        status: "success",
        placement: "top",
      });
      dispatch(resetActionType());
    } else if (studentState.ACTION_TYPE === updatePasswordError.toString()) {
      toast.show({
        title: "Error",
        description: studentState.updatePasswordMessage,
        status: "danger",
        placement: "top",
      });
      dispatch(resetActionType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentState.ACTION_TYPE]);

  return (
    <Center>
      <Stack
        my={10}
        px={10}
        flex={5}
        space={5}
        w={["95%", "80%", "60%", "50%"]}
      >
        <Input
          label="Password"
          placeholder="Password"
          value={oldpassword}
          onChangeText={(text) => {
            setOldPassword(text);
          }}
          secureTextEntry={showOldPassword}
          InputRightElement={
            <IconButton
              mr={1}
              icon={showOldPassword ? <FiEyeOff /> : <FiEye />}
              onPress={() => {
                setShowOldPassword(!showOldPassword);
              }}
            />
          }
        />

        <Input
          label="New Password"
          placeholder="New Password"
          value={newpassword}
          onChangeText={(text) => {
            setNewPassword(text);
          }}
          secureTextEntry={showNewPassword}
          InputRightElement={
            <IconButton
              mr={1}
              icon={showNewPassword ? <FiEyeOff /> : <FiEye />}
              onPress={() => {
                setShowNewPassword(!showNewPassword);
              }}
            />
          }
        />

        <Input
          label="Confirm Password"
          placeholder="Confirm Password"
          value={confirmpassword}
          onChangeText={(text) => {
            setConfirmPassword(text);
          }}
          secureTextEntry={showConfirmPassword}
          InputRightElement={
            <IconButton
              mr={1}
              icon={showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              onPress={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            />
          }
        />

        <Button
          mt={4}
          onPress={() => {
            if (!oldpassword || !newpassword || !confirmpassword) {
              toast.show({
                title: "Error",
                description: "Please fill all the fields",
                status: "danger",
                placement: "top",
              });
            } else if (newpassword !== confirmpassword) {
              toast.show({
                title: "Error",
                description: "New password and confirm password does not match",
                status: "danger",
                placement: "top",
              });
            } else {
              setShowAlert(true);
            }
          }}
        >
          Change Password
        </Button>
      </Stack>

      {/* loading modal for password change  */}
      <Loading
        isLoading={studentState.isUpdatePasswordLoading}
        loadingText=" Updating Password"
      />

      {/* update alert */}
      <Alert
        isOpen={showAlert}
        title="Change Password"
        message="Are you sure you want to change your password?"
        cancelText={"Cancel"}
        confirmText={"Change"}
        onCancel={() => {
          setShowAlert(false);
        }}
        onConfirm={() => {
          setShowAlert(false);
          updatePassword();
        }}
      />
    </Center>
  );
}
