import {
  getDashboardDataStart,
  getDashboardDataSuccess,
  getDashboardDataError,
  createAnnouncementStart,
  createAnnouncementSuccess,
  createAnnouncementError,
  editAnnouncementStart,
  editAnnouncementSuccess,
  editAnnouncementError,
  getDashboardAnnouncements,
  getDashboardApplicants,
  getDashboardStudents,
} from "../slices/AdminDashboardSlice";

import { setNewApplicantsCount } from "../slices/NavigationSlice";

// firebase
import { db } from "../../firebase/firebase-config";
import {
  collection,
  onSnapshot,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
} from "@firebase/firestore";

// applications middleware;
export const getApplicationsMiddleware = () => async (dispatch) => {
  dispatch(getDashboardDataStart());
  const q = query(collection(db, "users"));

  try {
    onSnapshot(q, (snapshot) => {
      const applicants = [];
      snapshot.forEach((d) => {
        applicants.push({ ...d.data(), id: d.id });
      });

      dispatch(getDashboardApplicants(applicants));
    });
  } catch (error) {
    dispatch(
      getDashboardDataError({
        message: error.message,
      })
    );
  }
};

// announcements middleware;
export const getAnnouncementMiddleware = () => async (dispatch, getState) => {
  dispatch(getDashboardDataStart());
  const q = query(collection(db, "announcements"));

  try {
    onSnapshot(q, (snapshot) => {
      const announcements = [];
      snapshot.forEach((d) => {
        announcements.push({ ...d.data(), id: d.id });
      });

      dispatch(getDashboardAnnouncements(announcements));
    });
  } catch (error) {
    dispatch(
      getDashboardDataError({
        message: error.message,
      })
    );
  }
};

// students middleware;
export const getStudentsMiddleware = () => async (dispatch, getState) => {
  dispatch(getDashboardDataStart());
  const q = query(collection(db, "app-users"), where("role", "==", "student"));

  try {
    onSnapshot(q, (snapshot) => {
      const students = [];
      snapshot.forEach((d) => {
        students.push({ ...d.data(), id: d.id });
      });

      dispatch(getDashboardStudents(students));
    });
  } catch (error) {
    dispatch(
      getDashboardDataError({
        message: error.message,
      })
    );
  }
};

// get new  applications count
export const getNewApplicantsCountMiddleware = () => async (dispatch) => {
  const q = query(collection(db, "users"), where("read", "==", false));
  try {
    onSnapshot(q, (snapshot) => {
      const unread = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(setNewApplicantsCount(unread.length));
    });
  } catch (error) {
    console.log(error.message);
  }
};

// create announcement middleware
export const createAnnouncementMiddleware =
  (data) => async (dispatch, getState) => {
    console.log(data);

    dispatch(createAnnouncementStart());

    try {
      const addAnnouncement = async () => {
        const announcements = await collection(db, "announcements");

        await addDoc(announcements, {
          ...data,
          dateCreated: new Date(),
          dateEdited: new Date(),
        });
      };
      await addAnnouncement().then(() => {
        dispatch(
          createAnnouncementSuccess({
            message: "Announcement created successfully",
          })
        );
      });
    } catch (error) {
      dispatch(
        createAnnouncementError({
          message: error.message,
        })
      );
    }
  };

//  edit announcement middleware
export const editAnnouncementMiddleware =
  (data, id) => async (dispatch, getState) => {
    console.log(id, "wo maame twe");

    dispatch(editAnnouncementStart());

    try {
      const updateAnnouncement = async () => {
        const announcements = await doc(db, "announcements", id);

        await updateDoc(announcements, { ...data, dateEdited: new Date() });
      };
      await updateAnnouncement().then(() => {
        dispatch(
          editAnnouncementSuccess({
            message: "Announcement updated successfully",
          })
        );
      });
    } catch (error) {
      dispatch(editAnnouncementError({ message: error.message }));
    }
  };
