import{HStack, Input, Button } from 'native-base'
import {MdSearch} from 'react-icons/md'

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <HStack>
    <Input
      id="search"
      type="text"
      placeholder="search users"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      borderRadius={0}
      borderTopLeftRadius="sm"
      borderBottomLeftRadius="sm"
      _focus={{ borderColor: "gray.200" }}
    />
    <Button
      zIndex={1}
      onClick={onClear}
      isDisabled
      _disabled={{ bg: "gray.500" }}
      borderRadius={0}
      colorScheme="red"
      borderTopRightRadius="sm"
      borderBottomRightRadius="sm"
    >
      <MdSearch size={20} color="white" />
    </Button>
  </HStack>
);
