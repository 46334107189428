import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: USE .ENV FILE
const firebaseConfig = {
  apiKey: "AIzaSyBn1LWTCo0xZG5bivn2yriNlJqmiwFT_3w",
  authDomain: "genuinestudentsportal.firebaseapp.com",
  projectId: "genuinestudentsportal",
  storageBucket: "genuinestudentsportal.appspot.com",
  messagingSenderId: "392735936434",
  appId: "1:392735936434:web:93fece59f9134db2845eab",
  measurementId: "G-01G3FDTLBB",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);