import React from "react";
import {
  Center,
  Box,
  Image,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Pressable,
  useToast,
  IconButton,
} from "native-base";
import logo from "../../assets/logo.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch } from "react-redux";

import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebase-config";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");

  const onForgotPassword = () => {
    if (!email) {
      toast.show({
        title: "Error",
        description: `Email cannot be empty`,
        placement: "top",
        status: "error",
      });
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.show({
          title: "Success",
          description: `Email sent to ${email}`,
          placement: "top",
          status: "success",
        });

        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/invalid-email") {
          toast.show({
            title: "Error",
            description: `Invalid email`,
            placement: "top",
            status: "error",
          });
        } else if (errorCode === "auth/user-not-found") {
          toast.show({
            title: "Error",
            description: `User not found`,
            placement: "top",
            status: "error",
          });
        } else {
          toast.show({
            title: "Error",
            description: `${errorMessage}`,
            placement: "top",
            status: "error",
          });
        }
      });
  };

  return (
    <Center h="100vh" bg="primary.500">
      <Box
        bg="secondary.50"
        shadow="4"
        w={["90%", "80%", "60%", "50%"]}
        p={[10]}
        rounded="md"
        alignItems={"center"}
      >
        <Image
          source={logo}
          h={[90, 100]}
          w={[150, 200]}
          resizeMode="contain"
        />

        <Stack space={8} w={["100%", "80%"]}>
          <Box>
            <Text color="white">Forgotten your password?</Text>
            <Text color="white">
              Don't sweat it. Just type your email and we will send you a link
              to reset your password.
            </Text>
          </Box>

          <FormControl>
            <FormControl.Label
              _text={{
                color: "white",
              }}
            >
              Email
            </FormControl.Label>
            <Input
              bg="white"
              size="lg"
              placeholder="user@domain.com"
              autoCapitalize="none"
              value={email}
              onChangeText={(text) => setEmail(text)}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  onForgotPassword();
                }
              }}
            />
          </FormControl>

          <Button
            size="lg"
            colorScheme="emerald"
            mb={5}
            onPress={() => onForgotPassword()}
          >
            GET PASSWORD RESET EMAIL
          </Button>

          <Pressable
            alignItems={"center"}
            mt={-5}
            mb={10}
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => navigate("/login")}
          >
            <Text color="white">Remember your password? Log in.</Text>
          </Pressable>
        </Stack>
      </Box>
    </Center>
  );
}
